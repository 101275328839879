import ServerCall from "../../serverCall";

const serverCall = ServerCall();
const getAllHelpRequest = (body) => {
  const response = serverCall.customAxios.post(
    `/help-request/get-all-help-requests`,
    body
  );
  return response;
};

const getRequestDetails = (requestId) => {
  const response = serverCall.customAxios.get(
    `/help-request/get-request-detail?requestId=${requestId}`
  );
  return response;
};

const createHelpRequest = (body) => {
  const response = serverCall.customAxios.post(
    `/help-request/getHelp/create-help-request`,
    body
  );
  return response;
};

const helpedByMe = (body, token) => {
  const response = serverCall.customAxios.post(
    `/help-request/giveHelp/helped-by-me`,
    body
  );
  return response;
};

const giveHelpPayment = (body, token) => {
  let response;
  if (token) {
    response = serverCall.customAxios.post(
      `/help-request/giveHelp/doners`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    response = serverCall.customAxios.post(
      `/help-request/giveHelp/doners`,
      body
    );
  }

  return response;
};

const getVendorPendingRequests = (body) => {
  const response = serverCall.customAxios.post(
    `/help-request/getHelp/get-vendor-pending-request`,
    body
  );
  return response;
};

const publishVendorRequest = (requestId) => {
  const response = serverCall.customAxios.post(
    `/help-request/getHelp/publish-vendor-request?requestId=${requestId}`
  );
  return response;
};

const getActionHelpDetails = (donateId) => {
  const response = serverCall.customAxios.get(
    `/help-request/giveHelp/action-helped-by-me?donateId=${donateId}`
  );
  return response;
};

const vendorCreateHelpRequest = (body) => {
  const response = serverCall.customAxios.post(
    `/help-request/vendor/create-help-request`,
    body
  );
  return response;
};

const getHelpAcccountDetails = (requestId, userId) => {
  let response;
  if (userId) {
    response = serverCall.customAxios.get(
      `/help-request/get-request-detail?requestId=${requestId}&userId=${userId}`
    );
  } else {
    response = serverCall.customAxios.get(
      `/help-request/get-request-detail?requestId=${requestId}`
    );
  }
  return response;
};

const changeHelpReqeustStatus = (requestId, status) => {
  const response = serverCall.customAxios.post(
    `/help-request/getHelp/update-request?requestId=${requestId}&status=${status}`
  );
  return response;
};

const HelpService = {
  getAllHelpRequest,
  getRequestDetails,
  createHelpRequest,
  helpedByMe,
  getVendorPendingRequests,
  publishVendorRequest,
  getActionHelpDetails,
  vendorCreateHelpRequest,
  giveHelpPayment,
  getHelpAcccountDetails,
  changeHelpReqeustStatus,
};

export default HelpService;
