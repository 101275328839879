import React from "react";

const AmountInput = ({ type, name, placeholder, formik }) => {
  return (
    <div className="relative h-10 flex gap-0.5 items-center w-full">
      {/* <PiCurrencyDollarThin className="text-xl text-primary" /> */}
      <p className="text-sm text-primary py-3 leading-none absolute left-[13px] top-[2.8px]">
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.62988 0.240234H4.52539V0.985352C4.97201 1.06738 5.35254 1.21322 5.66699 1.42285C5.986 1.63249 6.29134 1.94238 6.58301 2.35254L5.84473 2.94727C5.49382 2.40495 5.05404 2.07682 4.52539 1.96289V5.37402C5.7194 6.00293 6.46224 6.51335 6.75391 6.90527C7.1276 7.40658 7.31445 7.96029 7.31445 8.56641C7.31445 9.06771 7.17546 9.52799 6.89746 9.94727C6.62402 10.362 6.2526 10.6855 5.7832 10.918C5.47786 11.0684 5.05859 11.1777 4.52539 11.2461V13.3857H3.62988V11.2461C3.06934 11.2005 2.62044 11.1117 2.2832 10.9795C1.94596 10.8473 1.61556 10.6491 1.29199 10.3848C0.972982 10.1159 0.70638 9.80599 0.492188 9.45508L1.22363 8.90137C1.8571 9.73991 2.65918 10.207 3.62988 10.3027V6.07129C2.91439 5.72038 2.46094 5.48112 2.26953 5.35352C1.88216 5.08919 1.59505 4.79525 1.4082 4.47168C1.22135 4.14811 1.12793 3.77441 1.12793 3.35059C1.12793 2.94043 1.23047 2.5599 1.43555 2.20898C1.64062 1.85807 1.91862 1.57324 2.26953 1.35449C2.625 1.13574 3.07845 0.999023 3.62988 0.944336V0.240234ZM3.62988 1.89453C3.28809 1.92188 3.01237 1.99935 2.80273 2.12695C2.5931 2.25 2.42676 2.41862 2.30371 2.63281C2.18522 2.84245 2.12598 3.07487 2.12598 3.33008C2.12598 3.6582 2.22168 3.9362 2.41309 4.16406C2.66829 4.47396 3.07389 4.75423 3.62988 5.00488V1.89453ZM4.52539 10.2754C5.07682 10.1979 5.5166 9.9974 5.84473 9.67383C6.17741 9.35026 6.34375 8.98796 6.34375 8.58691C6.34375 8.19499 6.21615 7.83268 5.96094 7.5C5.71029 7.16732 5.23177 6.81185 4.52539 6.43359V10.2754Z"
            fill="#00253F"
          />
        </svg>
      </p>
      <input
        placeholder={placeholder}
        type={type}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        // autoComplete={autocompleteValue}
        className="amount-input"
      />
    </div>
  );
};

export default AmountInput;
