import React, { useEffect, useRef } from "react";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import moment from "moment";
import AvatarIcon from "./Avatar";
import { NumberWithCommas } from "./Common";

const UserCard = ({ data }) => {
  const navigate = useNavigate();
  const path = window.location.pathname;

  const handleProfileNavigate = (id) => {
    if (path.includes("give-help")) {
      navigate(`/user-give/help-profile/${id}`);
    } else {
      navigate(`/user-profile/${id}`);
    }
  };

  const handleRequestNavigate = (id) => {
    if (path.includes("give-help")) {
      navigate(`/user-give/help-request/${id}`);
    } else {
      navigate(`/request-detail/${id}`);
    }
  };

  return (
    <div className="user-card">
      {data?.map((user, index) => {
        return (
          <div
            key={index}
            className="p-4 shadow-card2 rounded-md bg-white cursor-pointer"
            onClick={() => handleRequestNavigate(user?._id)}
          >
            <div className="border-b border-primary/20 pb-[14px] flex items-center gap-2.5">
              <span className="py-1 px-2 bg-blue/10 rounded">
                <strong className="text-blue">{user?.category?.name}</strong>
              </span>
              <h6 className="text-sm">{user?.vendor?.company}</h6>
            </div>
            <div className="py-[14px] flex items-start justify-between">
              <div className="flex gap-3">
                <div className="h-[75px] w-[75px] md:h-20 md:w-20 rounded-lg overflow-hidden avatar">
                  {user.userData?.latestProfileImage ? (
                    <img
                      src={user.userData?.latestProfileImage}
                      alt="User"
                      className="object-cover bg-cover bg-center w-full h-full"
                    />
                  ) : (
                    <AvatarIcon
                      size={75}
                      name={user.customerName}
                      className="rounded-lg overflow-hidden"
                    />
                  )}
                </div>
                <div className="flex flex-col justify-between">
                  <div className="w-fit flex items-start flex-col">
                    <h6 className="font-normal leading-[19.62px] tab:leading-[22.07px] w-fit line-clamp-1 max-w-[110px]">
                      {user.customerName}
                    </h6>
                    <strong className="text-primary/60">
                      Updated: {moment(user.updatedAt).format("DD MMM YYYY")}
                    </strong>
                  </div>
                  <button
                    class="btn-small btn-outline-primary w-fit"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleProfileNavigate(user?._id);
                    }}
                  >
                    Visit Profile
                  </button>
                </div>
              </div>
              {user.userData?.isPayyroVerified && (
                <div className="flex gap-1 h-fit items-center">
                  <RiVerifiedBadgeFill className="text-success text-xs md:text-sm" />
                  <h6 className="text-10 md:text-xs leading-none">
                    Payyro Verified
                  </h6>
                </div>
              )}
            </div>
            <ProgressBar user={user} />
            <div className="pt-[14px] flex items-center justify-between gap-4">
              <span className="text-xs font-bold">
                ${NumberWithCommas(user.fundedAmount)} funded
              </span>
              <span className="text-xs font-bold">
                ${NumberWithCommas(user.requestedAmount)} requested
              </span>
            </div>
            <div className="pt-[14px] md:hidden">
              <p className="line-clamp-2">{user.address}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserCard;
