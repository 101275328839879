import ServerCall from "../../serverCall";

const serverCall = ServerCall();

const getAllNotification = (page, limit) => {
  const response = serverCall.customAxios.get(
    `notification/get-all-notification?page=${page}&limit=${limit}`
  );
  return response;
};

const NotificationService = { getAllNotification };

export default NotificationService;
