import React, { useEffect, useState } from "react";
import { RxSlash } from "react-icons/rx";
import { TiHome } from "react-icons/ti";
import location from "../../../assets/svg/location.svg";
import { useNavigate, useParams } from "react-router-dom";
import HelpService from "../../service/help.service";
import Toaster from "../../common/toaster";
import AvatarIcon from "../../common/Avatar";
import VerifyGreen from "../../../assets/svg/icon/verify-green.svg";
import Loader from "../../../assets/loader.gif";
import { scrollToTop } from "../../common/constatnts";

const UserGiveHelpProfile = () => {
  // const navigate = useNavigate();

  // const handleViewHelpRequest = () => {
  //   navigate("/user-give/help-request");
  // };
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleViewHelpRequest = () => {
    navigate(`/user-give/help-request/${id}`);
  };

  const getRequestDetails = async () => {
    try {
      const response = await HelpService.getRequestDetails(id);
      setData(response.data.data);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRequestDetails();
  }, [id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="bg-cgreen">
      {isLoading ? (
        <div className="flex justify-center mt-3">
          <img src={Loader} alt="Loader" className="w-14 h-14" />
        </div>
      ) : (
        <div className="grid gap-5 md:gap-30 pt-4 pb-5 tab:pt-26 tab:pb-11 containers">
          <div>
            <ol className="inline-flex items-center space-x-1 rtl:space-x-reverse">
              <li className="inline-flex items-center">
                <div
                  onClick={() => navigate("/give-help/dashboard")}
                  className="text-primary/50 hover:text-primary duration-200 cursor-pointer"
                >
                  <TiHome className="h-[22px] w-[22px]" />
                </div>
              </li>
              <RxSlash className="text-[#80929f]" />
              <li>
                <div className="flex items-center">
                  <div
                    onClick={() => navigate("/give-help/help")}
                    className="sm:text-base text-sm font-bold text-primary/50 hover:text-primary duration-200 cursor-pointer"
                  >
                    Help Requests
                  </div>
                </div>
              </li>
              <RxSlash className="text-primary/50" />
              <li className="font-bold text-primary">
                <div className="sm:text-base text-sm cursor-pointer">
                  {data.userData?.name}
                </div>
              </li>
            </ol>
          </div>
          <div className="flex flex-col lg:flex-row gap-30 lg:gap-[26px]">
            <div className="col-span-12 md:col-span-8 lg:max-w-[830px] w-full dashboard-card p-4 md:p-26">
              <div className="md:flex block justify-between b-b pb-4">
                <div className="flex justify-start items-start gap-4 md:pb-0 pb-4 mad:pb-0">
                  <div className="avatar">
                    {data.userData?.latestProfileImage ? (
                      <img
                        src={data.userData?.latestProfileImage}
                        alt=""
                        className="sm:h-20 sm:w-20 w-[60px] h-[60px] rounded-md object-cover"
                      />
                    ) : (
                      <AvatarIcon
                        size={60}
                        className="rounded-lg"
                        name={data.userData?.name}
                      />
                    )}
                  </div>
                  <div className="flex flex-col justify-between gap-2.5 h-full">
                    <div className="grid gap-1">
                      <h4 className="text-base sm:text-26 leading-none">
                        {data.userData?.name}'s Profile
                      </h4>
                      <div className="flex gap-1 items-center">
                        <img
                          src={location}
                          className="w-3 h-3 sm:w-[14px] sm:h-[14px]"
                        />
                        <span className="text-xs sm:text-sm text-gray-500 font-normal">
                          {data.address}
                        </span>
                      </div>
                    </div>
                    {data.userData?.isPayyroVerified && (
                      <div className="flex gap-1 h-fit items-center">
                        <div className="w-3 h-3 tab:w-[14px] tab:h-[14px]">
                          <img src={VerifyGreen} alt="Verify" />
                        </div>
                        {/* <RiVerifiedBadgeFill className="text-success text-xs md:text-sm" /> */}
                        <h6 className="text-10 sm:text-xs leading-none">
                          Payyro Verified
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className="btn-default btn-primary tab:w-fit w-full"
                    onClick={() => handleViewHelpRequest()}
                  >
                    View Help Requests
                  </button>
                </div>
              </div>

              <div className="lg:pt-4 pt-4 grid gap-2.5 tab:gap-3">
                <h5 className="text-[14px] sm:text-lg">My Bio</h5>
                <p className="pb-1 break-all">{data.userData?.bio}</p>
                {data?.userData?.video && (
                  <video autoPlay controls loop>
                    <source src={data?.userData?.video} />
                  </video>
                )}
              </div>
            </div>
            <div className="col-span-12 md:col-span-4 lg:max-w-[344px] w-full">
              <h5 className="text-[1rem] sm:text-xl mb-4">Image Gallery</h5>
              <div className="grid md:grid-cols-[repeat(auto-fill,minmax(106px,1fr))] grid-cols-[repeat(auto-fill,minmax(70px,1fr))] gap-[13px]">
                {data.userData?.profileImages?.map((img) => (
                  <div className="h-full w-full">
                    <img
                      src={img}
                      alt=""
                      className="h-20 w-20 lg:w-[106px] lg:h-[106px] rounded-md object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserGiveHelpProfile;
