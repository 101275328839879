import ServerCall from "../../serverCall";

const serverCall = ServerCall();

const getOwnProfile = (token) => {
  const response = serverCall.customAxios.get(
    `/users/get-own-profile`
    //   {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
  );

  return response;
};

const updateProfileImages = (data) => {
  const response = serverCall.customAxios.patch(`/users/profile-images`, data);
  return response;
};

const changePassword = (data) => {
  const response = serverCall.customAxios.patch(`/users/change-password`, data);
  return response;
};

const updateBio = (bio) => {
  const response = serverCall.customAxios.patch(`/users/edit-bio?bio=${bio}`);
  return response;
};

const updatePersonalDetails = (body) => {
  const response = serverCall.customAxios.patch(
    `/users/update-personal-detail`,
    body
  );
  return response;
};

const addIncomeDocuments = (body) => {
  const response = serverCall.customAxios.patch(`/users/income-document`, body);
  return response;
};

const editVideo = (body) => {
  const response = serverCall.customAxios.patch(`/users/edit-video`, body);
  return response;
};

const getMyHelpRequest = (body, token) => {
  const response = serverCall.customAxios.post(
    `/help-request/getHelp/get-my-request`,
    body
  );
  return response;
};

const getHelpDashboard = (token) => {
  const response = serverCall.customAxios.post(`/users/getHelp/dashboard`, {});
  return response;
};

const giveHelpDashboard = (token) => {
  const response = serverCall.customAxios.post(`/users/giveHelp/dashboard`, {});
  return response;
};

const UserService = {
  getOwnProfile,
  updateProfileImages,
  changePassword,
  updateBio,
  updatePersonalDetails,
  addIncomeDocuments,
  getHelpDashboard,
  giveHelpDashboard,
  getMyHelpRequest,
  editVideo,
};

export default UserService;
