import { get } from "lodash";
import Toaster from "../common/toaster";
import ServerCall from "../../serverCall";

const serverCall = ServerCall();
const login = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;

  try {
    const response = await serverCall.customAxios.post(
      `/auth/login?email=${email}&password=${password}`
    );
    const data = get(response, "data", null);

    if (data) {
      const role = data.data.accountType;
      const email = data.data.email;
      const isDeleteAccount = data.data.isDeleteAccount;
      const name = data.data.name;
      const id = data.data._id;
      const token = data.token;
      const customerId = data.data.customerId;

      const userProfile = {
        role,
        token,
        email,
        isDeleteAccount,
        name,
        id,
        customerId,
      };
      localStorage.setItem("payyro-store", JSON.stringify(userProfile));
    }
    return response;
  } catch (error) {
    Toaster("error", error.response.data.message);
  }
};

const signup = (body) => {
  const response = serverCall.customAxios.post(`/auth/register`, body);
  return response;
};

const setUpGetProfile = (body, token) => {
  const response = serverCall.customAxios.patch(
    `/users/getHelp/setup-profile`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

const setUpGiveProfile = (body, token) => {
  const response = serverCall.customAxios.patch(
    `/users/giveHelp/setup-profile`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

const forgotPassword = (email, type) => {
  const response = serverCall.customAxios.post(
    `/auth/forgotPassword?email=${email}&type=${type}`
  );
  return response;
};

const setNewPassword = (token, password) => {
  const response = serverCall.customAxios.post(
    `/auth/set-new-password?token=${token}&password=${password}`
  );
  return response;
};

const vendorLogin = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;

  try {
    const response = await serverCall.customAxios.post(
      `/vendor/login?email=${email}&password=${password}`
    );
    const data = get(response, "data", null);

    if (data) {
      const role = data.data.accountType;
      const email = data.data.email;
      const isDeleteAccount = data.data.isDeleteAccount;
      const name = data.data.name;
      const id = data.data._id;
      const token = data.token;
      const customerId = data.data.customerId;

      const userProfile = {
        role,
        token,
        email,
        isDeleteAccount,
        name,
        id,
        customerId,
      };
      localStorage.setItem("payyro-store", JSON.stringify(userProfile));
    }
    return response;
  } catch (error) {
    Toaster("error", error.response.data.message);
  }
};

const vendorSignup = (body) => {
  const response = serverCall.customAxios.post(`/vendor/register`, body);
  return response;
};

const createDevice = (body, token) => {
  const response = serverCall.customAxios.post(`/device/create-device`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

const createGuest = (name, email) => {
  const response = serverCall.customAxios.post(
    `/auth/guest/create-guest?name=${name}&email=${email}`
  );
  return response;
};

const logout = (deviceToken) => {
  const response = serverCall.customAxios.post(
    `/device/log-out?deviceToken=${deviceToken}`
  );
  return response;
};

const AuthenticationService = {
  login,
  signup,
  forgotPassword,
  vendorLogin,
  vendorSignup,
  setUpGiveProfile,
  setUpGetProfile,
  createDevice,
  logout,
  setNewPassword,
  createGuest,
};

export default AuthenticationService;
