import React from "react";
import NotFoundImage from "../../assets/notfound.png";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-screen flex-col gap-y-5 p-5">
      <img src={NotFoundImage} alt="NotFoundImage" className="w-[500px]" />
      <p className="text-2xl text-danger">Page Not Found</p>
      <button
        onClick={() => navigate(-1)}
        className="btn-big btn-outline-primary"
      >
        Go Back
      </button>
    </div>
  );
};

export default NotFound;
