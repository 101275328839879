import ServerCall from "../../serverCall";

const serverCall = ServerCall();

const addCard = (body) => {
  const response = serverCall.customAxios.post(`/stripe/add-card`, body);
  return response;
};

const setCardPreference = (cardId) => {
  const response = serverCall.customAxios.post(
    `/stripe/card/set-preference?cardId=${cardId}`
  );
  return response;
};

const addAccount = (body) => {
  const response = serverCall.customAxios.post(`/stripe/add-account`, body);
  return response;
};

const setAccountPreference = (cardId) => {
  const response = serverCall.customAxios.post(
    `/stripe/account/set-preference?accountId=${cardId}`
  );
  return response;
};

const deleteCard = (cardId) => {
  const response = serverCall.customAxios.delete(
    `/stripe/delete-card?cardId=${cardId}`
  );
  return response;
};

const createCardPaymentIntent = (body) => {
  const response = serverCall.customAxios.post(
    `/stripe/card/create-payment-intent`,
    body
  );
  return response;
};

const createBankPaymentIntent = (body) => {
  const response = serverCall.customAxios.post(
    `/stripe/bank-account/create-payment-intent`,
    body
  );
  return response;
};

const StripeService = {
  addCard,
  addAccount,
  deleteCard,
  setCardPreference,
  setAccountPreference,
  createCardPaymentIntent,
  createBankPaymentIntent,
};

export default StripeService;
