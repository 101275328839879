import React from "react";
import { FaAngleRight, FaChevronRight } from "react-icons/fa";
import { FaAnglesRight } from "react-icons/fa6";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";

const CustomPagination = ({
  total,
  page,
  handleChange,
  dataPerPage,
  handleDataPerPage,
  PAGE_SIZES,
}) => {
  const totalPages = Math.ceil(total / dataPerPage);

  const pageNumbers = [];

  // Always add first page
  pageNumbers.push(1);

  // Add ellipsis if current page is not 1 or 2
  if (page > 2) {
    pageNumbers.push("...");
  }

  // Add current page if it's not 1 or the last page
  if (page !== 1 && page !== totalPages) {
    pageNumbers.push(page);
  }

  // Add ellipsis if current page is not the last or second to last
  if (page < totalPages - 1) {
    pageNumbers.push("...");
  }

  // Always add last page if it's not the same as the first page
  if (totalPages > 1) {
    pageNumbers.push(totalPages);
  }

  return (
    <div className="flex md:flex-row flex-col justify-between items-center gap-4">
      <div className="flex gap-5 items-center">
        <p>
          Showing {(page - 1) * dataPerPage + 1} to{" "}
          {Math.min(page * dataPerPage, total)} of {total} entries
        </p>
        <select
          className="border border-gray-600 focus-within:outline-none rounded px-2 py-1"
          onChange={handleDataPerPage}
        >
          {PAGE_SIZES.map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <div className="flex justify-center flex-col">
        <ul className="inline-flex items-center">
          <li>
            <button
              type="button"
              onClick={() => handleChange("First", page)}
              className={`flex justify-center font-semibold p-2 rounded-full transition bg-white-light text-dark ${
                page === 1
                  ? "cursor-default"
                  : "hover:text-white hover:bg-primary dark:text-white-light"
              }`}
            >
              <MdOutlineKeyboardDoubleArrowRight className="w-5 h-5 rotate-180" />
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => handleChange("Previous", page)}
              className={`flex justify-center font-semibold p-2 rounded-full transition bg-white-light text-dark ${
                page === 1
                  ? "cursor-default"
                  : "hover:text-white hover:bg-primary dark:text-white-light"
              }`}
            >
              <MdOutlineKeyboardArrowRight className="w-5 h-5 rotate-180" />
            </button>
          </li>
          {pageNumbers.map((pageNumber, index) => (
            <li key={index}>
              {pageNumber === "..." ? (
                <span className="px-2 py-2">....</span>
              ) : (
                <button
                  type="button"
                  className={`flex justify-center items-center text-sm font-semibold h-8 w-8 rounded-full transition ${
                    page === pageNumber
                      ? "bg-primary text-white dark:text-white-light dark:bg-primary"
                      : "bg-white-light text-dark hover:text-white hover:bg-primary dark:text-white-light"
                  }`}
                  onClick={() => handleChange("", pageNumber)}
                >
                  {pageNumber}
                </button>
              )}
            </li>
          ))}
          <li>
            <button
              type="button"
              onClick={() => handleChange("Next", page)}
              className={`flex justify-center font-semibold p-2 rounded-full transition bg-white-light text-dark ${
                page === totalPages
                  ? "cursor-default"
                  : "hover:text-white hover:bg-primary dark:text-white-light"
              }`}
            >
              <MdOutlineKeyboardArrowRight className="w-5 h-5 " />
            </button>
          </li>
          <li className="m-0">
            <button
              type="button"
              onClick={() => handleChange("Last", page)}
              className={`flex justify-center font-semibold p-2 rounded-full transition bg-white-light text-dark ${
                page === totalPages
                  ? "cursor-default"
                  : "hover:text-white hover:bg-primary dark:text-white-light"
              }`}
            >
              <MdOutlineKeyboardDoubleArrowRight className="text-xl" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CustomPagination;
