import React, { useContext } from "react";
import { AiFillFileExcel, AiFillFileUnknown, AiFillHome } from "react-icons/ai";
import {
  BsFileEarmarkTextFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import { FaAddressCard, FaHandHoldingHeart } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import { AuthContext } from "../../context/authContext";
import { LocalData } from "../common/Common";
import { useNavigate } from "react-router-dom";

const DashboardSidebar = () => {
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);
  // const role = "USER";
  // const type = "GETHELP";
  const path = window.location.pathname;

  const payyroStore = LocalData();

  let mainSidebar = [];

  if (role === "Get Help" || payyroStore?.role === "Get Help") {
    mainSidebar = [
      {
        link: "/get-help/dashboard",
        icon: (
          <AiFillHome
            className={`text-22 ${
              path.includes("/get-help/dashboard")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "Dashboard",
      },
      {
        link: "/get-help/user-get-help",
        icon: (
          <FaHandHoldingHeart
            className={`text-22 ${
              path.includes("/get-help/user-get-help")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "Get Help",
      },
      {
        link: "/get-help/my-help-request",
        icon: (
          <BsFileEarmarkTextFill
            className={`text-22 ${
              path.includes("/get-help/my-help-request")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "My Help Requests",
      },
      {
        link: "/get-help/create-request",
        icon: (
          <AiFillFileUnknown
            className={`text-22 ${
              path.includes("/get-help/create-request")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "Vendor Created Requests",
      },
    ];
  } else if (role === "Give Help" || payyroStore?.role === "Give Help") {
    mainSidebar = [
      {
        link: "/give-help/dashboard",
        icon: (
          <AiFillHome
            className={`text-22 ${
              path.includes("/give-help/dashboard")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "Dashboard",
      },
      {
        link: "/give-help/help",
        icon: (
          <FaAddressCard
            className={`text-22 ${
              path.includes("/give-help/help")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "Give help",
      },
    ];
  } else {
    mainSidebar = [
      {
        link: "/vendor/dashboard",
        icon: (
          <AiFillHome
            className={`text-22 ${
              path.includes("/vendor/dashboard")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "Dashboard",
      },
      {
        link: "/vendor/help-request",
        icon: (
          <FaHandHoldingHeart
            className={`text-22 ${
              path.includes("/vendor/help-request")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "Help Request for Customers",
      },
      {
        link: "/vendor/upload-bulk-request",
        icon: (
          <AiFillFileExcel
            className={`text-22 ${
              path.includes("/vendor/upload-bulk-request")
                ? "text-success"
                : "text-primary/70 group-hover:text-primary"
            }`}
          />
        ),
        name: "Upload Bulk Help Requests",
      },
    ];
  }

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="fixed top-[130px] left-[30px] w-[285px] h-[calc(100vh-200px)] overflow-y-auto z-50">
      <div className="dashboard-card px-4 pt-4 pb-30">
        <ul>
          {mainSidebar.map((menu) => (
            <li>
              <div
                onClick={() => handleNavigate(menu.link)}
                className={`group flex gap-3 items-center px-4 py-5 ${
                  path === menu.link ? "bg-primary/5" : ""
                } rounded-10 cursor-pointer`}
              >
                {menu.icon}
                <strong
                  className={`text-sm ${
                    path === menu.link ? "text-primary" : "text-primary/70"
                  } group-hover:text-primary`}
                >
                  {menu.name}
                </strong>
              </div>
            </li>
          ))}
        </ul>
        <div className="py-4 w-full flex items-center gap-1.5">
          <p className="leading-none">
            <strong className="text-primary/40 leading-none">GENERAL</strong>
          </p>
          <div className="h-[1px] w-full bg-primary/20 mb-[1px]"></div>
        </div>
        <ul>
          {/* <li>
            <div
              onClick={() => navigate("/settings")}
              className="flex gap-3 items-center px-4 py-5 rounded-10 group cursor-pointer"
            >
              <IoSettings
                className={`text-22 ${
                  path === "/settings"
                    ? "text-success"
                    : "text-primary/70 group-hover:text-primary"
                }`}
              />
              <strong
                className={`text-sm ${
                  path === "/settings" ? "text-primary" : "text-primary/70"
                } group-hover:text-primary`}
              >
                Settings
              </strong>
            </div>
          </li> */}
          <li>
            <div
              onClick={() => navigate("/user-faqs")}
              className="flex gap-3 items-center px-4 py-5 rounded-10 group cursor-pointer"
            >
              <BsFillQuestionCircleFill
                className={`text-22 ${
                  path.includes("/user-faqs")
                    ? "text-success"
                    : "text-primary/70 group-hover:text-primary"
                }`}
              />
              <strong
                className={`text-sm ${
                  path.includes("/user-faqs")
                    ? "text-primary"
                    : "text-primary/70"
                } group-hover:text-primary`}
              >
                FAQs
              </strong>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardSidebar;
