import React, { useEffect, useRef, useState } from "react";
import Location from "../../../assets/svg/icon/location-dark.svg";
import VerifyBlue from "../../../assets/svg/icon/verify-blue.svg";
import Lock from "../../../assets/svg/icon/lock.svg";
import VerifyGreen from "../../../assets/svg/icon/verify-green.svg";
import {
  AiFillDelete,
  AiFillEdit,
  AiFillFileExcel,
  AiFillMail,
} from "react-icons/ai";
import { FaUserLarge } from "react-icons/fa6";
import { MdOutlineAdd } from "react-icons/md";
import UseDialogState from "../../../hooks/UseDialogState";
import ChangePassword from "../../common/ChangePassword";
import { useNavigate } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { LuUpload } from "react-icons/lu";
import UploadDocument from "./UploadDocument";
import UserService from "../../service/user.service";
import Toaster from "../../common/toaster";
import { useFormik } from "formik";
import * as yup from "yup";
import UserBio from "../../common/UserBio";
import Loader from "../../../assets/loader.gif";
import { BsFileEarmarkPdfFill, BsFileEarmarkWordFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { scrollToTop } from "../../common/constatnts";
import { BiSolidFileJpg, BiSolidFilePng } from "react-icons/bi";
import AvatarIcon from "../../common/Avatar";
import { formatFileSize } from "../../common/Common";

const UserGetHelpProfile = () => {
  const {
    open,
    handleClickOpen,
    handleClickClose,
    openChangePassword,
    handleClickCloseChangePassword,
    handleClickOpenChangePassword,
    openAddDocument,
    handleClickCloseAddDocument,
    handleClickOpenAddDocument,
    bio,
    handleOpenBio,
    handleCloseBio,
  } = UseDialogState();
  const navigate = useNavigate();
  const passwordRef = useRef();
  const bioRef = useRef();
  const { token } = useSelector((state) => state.auth);
  // const videoRef = useRef();
  const documentRef = useRef();
  const [data, setData] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [documentDeleteIndex, setDocumentDeleteIndex] = useState();
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [documentLoader, setDocumentLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [videoLoader, setVideoLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  const handlePasswordChange = () => {
    handleClickOpenChangePassword();
    document.body.style.overflow = "hidden";
  };

  const handleEditprofile = () => {
    navigate("/edit-profile", { state: { pathname: "gethelp" } });
  };

  const handleAddDocument = () => {
    handleClickOpenAddDocument();
    document.body.style.overflow = "hidden";
  };

  const handleBioChange = () => {
    handleOpenBio();
    document.body.style.overflow = "hidden";
  };

  const getOwnProfile = async () => {
    setIsLoading(true);
    try {
      const response = await UserService.getOwnProfile(token);
      setData(response.data.data);
      setSelectedImages(response.data.data.profileImages);
      setUploadedFiles(response.data.data.incomedocuments);
      setSelectedVideo(response.data.data.video);
      formik.setFieldValue("bio", response.data.data.bio);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      bio: "",
    },
    validationSchema: yup.object().shape({
      bio: yup.string().required("Bio is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await UserService.updateBio(values.bio);
        Toaster("success", response.data.message);
        handleCloseBio();
        getOwnProfile();
        handleScrollChange();
      } catch (error) {
        Toaster("error", error.response.data.message);
      }
    },
  });

  const processImages = async (files) => {
    if (selectedImages.length + files.length > 5) {
      Toaster("error", "Maximum 5 images are allowed");
      return;
    }

    setImageLoader(true);
    const formData = new FormData();
    files.forEach((img) => formData.append("profileImages", img));

    try {
      const response = await UserService.updateProfileImages(formData);
      getOwnProfile();
      Toaster("success", response.data.message);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setImageLoader(false);
    }

    const fileURLs = files.map((img) => URL.createObjectURL(img));
    setSelectedImages((prev) => [...prev, ...fileURLs]);
  };

  const handleChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    await processImages(files);
  };

  const handleDrop = async (e, type) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);

    if (type === "image") {
      await processImages(files);
    } else {
      processFiles(files);
    }
  };

  const deleteImages = async (index) => {
    setDeleteLoader(true);
    setDeleteIndex(index);
    const images = selectedImages.filter((_, i) => i === index);

    const formData = new FormData();
    images.map((img) => formData.append("deletedImages", img));
    try {
      const response = await UserService.updateProfileImages(formData);
      getOwnProfile();
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setDeleteLoader(false);
      setDeleteIndex("");
    }
  };

  const processFiles = (files) => {
    setIsFileUpload(true);
    // const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadstart = () => {
        setUploadProgress((prev) => ({ ...prev, [file.name]: 0 }));
      };
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
          if (progress === 100) {
            setTimeout(() => {
              setIsFileUpload(false);
            }, 1000);
          }
        }
      };
      reader.onloadend = () => {
        setUploadedFiles((prev) => [...prev, file]);
        setUploadProgress((prev) => ({ ...prev, [file.name]: 100 }));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };

  const handleScrollChange = () => {
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    getOwnProfile();
  }, []);

  const closePassword = () => {
    handleClickCloseChangePassword();
    handleScrollChange();
  };

  const closeDocument = () => {
    setIsFileUpload(false);
    handleClickCloseAddDocument();
    handleScrollChange();
    setUploadProgress({});
    setUploadedFiles([]);
  };

  // const closeVideo = () => {
  //   handleClickClose();
  //   handleScrollChange();
  // };

  const closeBio = () => {
    handleCloseBio();
    getOwnProfile();
    formik.resetForm();
    handleScrollChange();
  };

  const handleOutsideClick = (event) => {
    if (passwordRef.current && !passwordRef.current.contains(event.target)) {
      handleClickCloseChangePassword();
      document.body.style.overflow = "unset";
    }
    // if (videoRef.current && !videoRef.current.contains(event.target)) {
    //   handleClickClose();
    //   document.body.style.overflow = "unset";
    // }
    if (bioRef.current && !bioRef.current.contains(event.target)) {
      handleCloseBio();
      document.body.style.overflow = "unset";
    }
    if (documentRef.current && !documentRef.current.contains(event.target)) {
      closeDocument();
      document.body.style.overflow = "unset";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleDocumentSubmit = async () => {
    setDocumentLoader(true);
    const formData = new FormData();
    uploadedFiles.map((file) => formData.append("document", file));
    try {
      const response = await UserService.addIncomeDocuments(formData);
      Toaster("success", response.data.message);
      handleClickCloseAddDocument();
      handleScrollChange();
      getOwnProfile();
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setDocumentLoader(false);
      setIsFileUpload(false);
      setUploadProgress({});
      setUploadedFiles([]);
    }
  };

  const deleteDocument = async (index) => {
    setDeleteLoader(true);
    setDocumentDeleteIndex(index);
    const files = uploadedFiles.filter((_, i) => i === index);
    const formData = new FormData();

    files.map((file) => formData.append("deletedDocuments", file.document));
    try {
      const response = await UserService.addIncomeDocuments(formData);
      Toaster("success", "Document Deleted Successfully!");
      getOwnProfile();
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setDocumentDeleteIndex("");
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleEditVideo = async (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(video.src);
        if (video.duration <= 60) {
          setVideoLoader(true);
          const formData = new FormData();
          formData.append("video", file);

          try {
            const response = await UserService.editVideo(formData);
            Toaster("success", response.data.message);
            getOwnProfile();
          } catch (error) {
            Toaster("error", error.response.data.message);
          } finally {
            setVideoLoader(false);
          }

          const fileURLs = URL.createObjectURL(file);
          setSelectedVideo(fileURLs);
        } else {
          Toaster(
            "error",
            `${file.name} is longer than 60 seconds and cannot be uploaded.`
          );
        }
      };

      video.src = URL.createObjectURL(file);
    });

    // Clear the input so the same file can be selected again if needed
    e.target.value = "";

    // const video = document.createElement("video");
    // video.preload = "metadata";

    // video.onloadedmetadata = async function () {
    //   window.URL.revokeObjectURL(video.src);

    //   if (video.duration <= 60) {
    //     const formData = new FormData();
    //     formData.append("video", files);

    //     try {
    //       const response = await UserService.editVideo(formData);
    //       Toaster("success", response.data.message);
    //       getOwnProfile();
    //     } catch (error) {
    //       Toaster("error", error.response.data.message);
    //     }

    //     const fileURLs = URL.createObjectURL(files);
    //     setSelectedVideo(fileURLs);
    //   } else {
    //     Toaster(
    //       "error",
    //       `${files.name} is longer than 60 seconds and cannot be uploaded.`
    //     );
    //   }
    // };

    // video.src = URL.createObjectURL(files);

    // e.target.value = "";
    // setSelectedVideo(video);
  };

  return (
    <div className="dashboard-part flex lg:gap-9 min-h-[calc(100vh-195px)]">
      {/* Dashboard Total Uer Card */}

      <div className="dashboard-card w-full">
        <div className="p-4 tab:px-26 tab:py-5 b-b">
          <h6 className="text-base">My Profile</h6>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <img src={Loader} alt="Loader" className="h-16 my-3" />
          </div>
        ) : (
          <>
            <div className="px-5 tab:px-26">
              <div className="py-5 tab:py-26 flex flex-col gap-26 b-b">
                <div className="flex justify-between gap-4">
                  <div className="flex items-center gap-4">
                    <div className="h-10 w-10 tab:h-[60px] tab:w-[60px] rounded-full overflow-hidden avatar">
                      {data.latestProfileImage ? (
                        <img
                          src={data.latestProfileImage}
                          alt="Profile"
                          className="w-full h-full bg-center bg-cover object-cover"
                        />
                      ) : (
                        <AvatarIcon
                          size={60}
                          className="rounded-full"
                          name={data?.name}
                        />
                      )}
                    </div>
                    <h5 className="text-base tab:text-26">{data.name}</h5>
                  </div>
                  <div>
                    <button
                      className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group"
                      onClick={handleEditprofile}
                    >
                      <AiFillEdit className="text-primary text-base group-hover:text-white duration-200" />
                      Edit Profile
                    </button>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2.5 pb-2.5">
                    <AiFillMail className="text-base" />
                    <p>{data.email}</p>
                  </div>
                  {data.address && (
                    <div className="flex items-center gap-2.5 pb-4">
                      <div className="h-4 w-4">
                        <img src={Location} alt="Location" />
                      </div>
                      <p>{data.address}</p>
                    </div>
                  )}
                  <div className="flex items-center pb-4 whitespace-nowrap">
                    {data.isIncomeVerified && (
                      <>
                        <div className="flex items-center gap-2.5 border-r border-primary pe-2.5 tab:pe-4">
                          <img src={VerifyBlue} alt="Verify" />
                          <strong className="text-10 tab:text-xs">
                            Income Verified
                          </strong>
                        </div>
                        <div className="ps-2.5 tab:ps-4">
                          <p className="text-10 tab:text-xs">
                            Get Help Eligibility
                          </p>
                        </div>
                      </>
                    )}

                    {data.isPayyroVerified && (
                      <div className="flex items-center gap-1 tab:gap-2.5 ps-1.5">
                        <img src={VerifyGreen} alt="Verify" />
                        <strong className="text-10 tab:text-xs">
                          Payyro Verified
                        </strong>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-2.5 tab:gap-4">
                  <div className="flex items-center gap-1.5">
                    <FaUserLarge className="text-primary text-base" />
                    <strong className="text-xs tab:text-base">
                      Account Type
                    </strong>
                  </div>
                  <span class="py-1 px-3 bg-blue/10 rounded-full">
                    <strong class="text-blue">{data.accountType}</strong>
                  </span>
                </div>
                <div
                  className="flex items-center gap-1.5 cursor-pointer"
                  onClick={handlePasswordChange}
                >
                  <img src={Lock} alt="Lock" />
                  <strong className="text-xs tab:text-base underline underline-offset-4">
                    Change Password
                  </strong>
                </div>
              </div>
              <div className="py-5 tab:py-26 b-b">
                <div className="flex justify-between gap-4 items-center tab:items-start">
                  <h6 className="text-sm tab:text-lg">About Me</h6>
                  <button
                    className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group"
                    onClick={handleBioChange}
                  >
                    <AiFillEdit className="text-primary text-base group-hover:text-white duration-200" />
                    Edit Bio
                  </button>
                </div>
                <p className="pt-26 text-xs tab:text-sm">{data.bio}</p>
              </div>

              {/* Documents */}
              <div className="py-5 tab:py-26 b-b">
                <div className="flex justify-between gap-2 items-center tab:items-start pb-26">
                  <h6 className="text-sm tab:text-lg">Income Details</h6>
                  <button
                    className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group"
                    onClick={handleAddDocument}
                  >
                    <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
                    Add Documents
                  </button>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                  {data.incomedocuments != ""
                    ? data.incomedocuments?.map((file, index) => {
                        return (
                          <div
                            className="flex justify-between items-start border border-primary/20 w-full rounded-lg p-2.5"
                            key={index}
                          >
                            <div className="flex justify-start items-start gap-2.5">
                              <div
                                className={`${
                                  file.name?.endsWith(".pdf")
                                    ? "text-red-500"
                                    : file.name?.endsWith(".xls") ||
                                      file.name?.endsWith(".xlsx")
                                    ? "text-green-500"
                                    : file.name?.endsWith(".jpg") ||
                                      file.name?.endsWith(".jpeg")
                                    ? "text-purple-400"
                                    : file.name?.endsWith(".png") ||
                                      file.name?.endsWith(".png")
                                    ? "text-yellow"
                                    : "text-blue"
                                } text-2xl`}
                              >
                                {file.name?.endsWith(".pdf") ? (
                                  <BsFileEarmarkPdfFill />
                                ) : file.name?.endsWith(".xls") ||
                                  file.name?.endsWith(".xlsx") ? (
                                  <AiFillFileExcel />
                                ) : file.name?.endsWith(".jpg") ||
                                  file.name?.endsWith(".jpeg") ? (
                                  <BiSolidFileJpg />
                                ) : file.name?.endsWith(".png") ||
                                  file.name?.endsWith(".png") ? (
                                  <BiSolidFilePng />
                                ) : (
                                  <BsFileEarmarkWordFill />
                                )}
                              </div>
                              <div>
                                <h6
                                  className="text-base break-all line-clamp-1"
                                  title={file.name}
                                >
                                  {file.name}
                                </h6>
                                <p className="text-sm">
                                  {formatFileSize(file.size)}
                                </p>
                              </div>
                            </div>

                            <div className="flex flex-col items-end">
                              {deleteLoader ? (
                                <>
                                  {documentDeleteIndex === index ? (
                                    <img
                                      src={Loader}
                                      alt="Loader"
                                      className="w-[20px] h-[20px]"
                                    />
                                  ) : (
                                    <IoMdCloseCircle
                                      className="h-4 w-4 text-primary/40 cursor-pointer"
                                      onClick={() => deleteDocument(index)}
                                    />
                                  )}
                                </>
                              ) : (
                                <IoMdCloseCircle
                                  className="h-4 w-4 text-primary/40 cursor-pointer"
                                  onClick={() => deleteDocument(index)}
                                />
                              )}

                              <div
                                className={`flex items-center justify-center px-1.5 py-1 rounded-full mt-3 ${
                                  data.isVerified
                                    ? "bg-success/25"
                                    : "bg-danger/30"
                                }`}
                              >
                                <strong
                                  className={`text-[10px] text-nowrap ${
                                    data.isVerified
                                      ? "text-darkGreen"
                                      : "text-danger"
                                  }`}
                                >
                                  {data.isVerified
                                    ? "Verified"
                                    : "Not Verified"}
                                </strong>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
              <div className="py-5 tab:py-26 b-b">
                <div className="flex justify-between gap-4 items-center tab:items-start">
                  <div>
                    <h6 className="text-sm tab:text-lg">
                      My video for Donors in support of my Help Requests
                    </h6>
                    <p>(Max. 60 seconds allowed)</p>
                  </div>
                  {videoLoader ? (
                    <div className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 !h-[37.6px] !w-[144.2px] leading-none group cursor-pointer justify-center">
                      <img src={Loader} alt="Loader" className="w-8 h-8" />
                    </div>
                  ) : (
                    <label
                      htmlFor="uploadvideo"
                      className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group cursor-pointer"
                    >
                      <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
                      Edit Video
                    </label>
                  )}
                  <input
                    type="file"
                    onChange={(e) => handleEditVideo(e)}
                    hidden
                    id="uploadvideo"
                    accept="video/*"
                  />
                </div>
                <div className="mt-4 max-w-[50%]">
                  <video
                    controls
                    autoPlay
                    className="rounded-xl cursor-pointer"
                  >
                    <source src={selectedVideo}></source>
                  </video>
                </div>
              </div>
              <div className="py-5 tab:py-26 b-b">
                <div className="flex justify-between gap-2 items-center tab:items-start pb-26">
                  <div>
                    <h6 className="text-sm tab:text-lg">Profile Images</h6>
                    <p className="text-xs tab:text-13">
                      (Max. 5 images allowed)
                    </p>
                  </div>
                  {imageLoader ? (
                    <div className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 !h-[37.16px] !w-[147.58px] leading-none group cursor-pointer justify-center">
                      <img src={Loader} alt="Loader" className="w-8 h-8" />
                    </div>
                  ) : (
                    <label
                      htmlFor="upload"
                      className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group cursor-pointer"
                      type="button"
                    >
                      <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
                      Add Image
                    </label>
                  )}
                </div>
                <div className="flex justify-start items-center gap-4">
                  {selectedImages &&
                    selectedImages?.map((img, index) => {
                      return (
                        <div
                          className="w-[140px] h-[140px] relative"
                          key={index}
                        >
                          <img
                            src={img}
                            alt=""
                            className="rounded-10 w-full h-full object-cover"
                          />
                          <div
                            className="w-8 h-8 bg-white/80 absolute top-2 right-2 rounded-10 cursor-pointer"
                            onClick={() => deleteImages(index)}
                          >
                            {deleteLoader ? (
                              <>
                                {deleteIndex === index ? (
                                  <img src={Loader} alt="Loader" />
                                ) : (
                                  <AiFillDelete className="text-red-500 text-17 content-center" />
                                )}
                              </>
                            ) : (
                              <AiFillDelete className="text-red-500 text-17 content-center" />
                            )}
                          </div>
                        </div>
                      );
                    })}

                  {selectedImages.length < 5 && (
                    <div
                      className="bg-primary/10 w-[140px] h-[140px] rounded-10 flex items-center p-1"
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => handleDrop(e, "image")}
                    >
                      <div className="mx-auto">
                        <label
                          htmlFor="upload"
                          className="w-full flex flex-col justify-center items-center"
                        >
                          <div className="m-4 mt-0 w-8 h-8 bg-white/60 rounded-lg flex justify-center items-center">
                            <LuUpload className="text-17" />
                          </div>
                          <div className="text-center">
                            Browse or Drag image to upload
                          </div>
                        </label>
                      </div>
                    </div>
                  )}
                  <input
                    type="file"
                    multiple
                    onChange={(e) => handleChangeFile(e)}
                    hidden
                    id="upload"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {openChangePassword && (
          <ChangePassword
            open={openChangePassword}
            handleClickClose={closePassword}
            passwordRef={passwordRef}
            type="user"
          />
        )}

        {openAddDocument && (
          <UploadDocument
            open={openAddDocument}
            handleClickClose={closeDocument}
            documentRef={documentRef}
            handleFileUpload={handleFileUpload}
            uploadProgress={uploadProgress}
            uploadedFiles={uploadedFiles}
            isFileUpload={isFileUpload}
            handleDocumentSubmit={handleDocumentSubmit}
            documentLoader={documentLoader}
            handleDrop={handleDrop}
          />
        )}
        {bio && (
          <UserBio
            bio={bio}
            handleCloseBio={closeBio}
            bioRef={bioRef}
            formik={formik}
          />
        )}
      </div>
    </div>
  );
};

export default UserGetHelpProfile;
