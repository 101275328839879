import React, { useState, useEffect, useRef } from "react";
import Location from "../../../assets/svg/icon/location-dark.svg";
import VerifyBlue from "../../../assets/svg/icon/verify-blue.svg";
import Lock from "../../../assets/svg/icon/lock.svg";
import VerifyGreen from "../../../assets/svg/icon/verify-green.svg";
import { AiFillDelete, AiFillEdit, AiFillMail } from "react-icons/ai";
import { FaUserLarge } from "react-icons/fa6";
import { MdOutlineAdd } from "react-icons/md";
import citi from "../../../assets/svg/citi.svg";
import { IoCloseOutline } from "react-icons/io5";
import AddAccount from "../../common/AddAccount";
import UseDialogState from "../../../hooks/UseDialogState";
import ChangePassword from "../../common/ChangePassword";
import AddGiveHelpCard from "./AddGiveHelpCard";
import { useNavigate } from "react-router-dom";
import { LuUpload } from "react-icons/lu";
import UserService from "../../service/user.service";
import Toaster from "../../common/toaster";
import Loader from "../../../assets/loader.gif";
import UserBio from "../../common/UserBio";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { scrollToTop } from "../../common/constatnts";
import AvatarIcon from "../../common/Avatar";
import StripeService from "../../service/stripe.service";

// Stripe
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

const GiveHelpUserProfile = () => {
  const {
    open,
    handleClickOpen,
    handleClickClose,
    openChangePassword,
    handleClickCloseChangePassword,
    handleClickOpenChangePassword,
    openAddCard,
    handleClickOpenAddCard,
    handleClickCloseAddCard,
    bio,
    handleOpenBio,
    handleCloseBio,
  } = UseDialogState();
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const passwordRef = useRef();
  const helpRef = useRef();
  const bioRef = useRef();
  const accountRef = useRef();
  const [data, setData] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [cardDeleteLoading, setCardDeleteLoading] = useState("");
  const [cardIsLoading, setCardIsLoading] = useState(false);
  const [accountIsLoading, setAccountIsLoading] = useState(false);
  const [preferId, setPreferId] = useState("");

  const handleAddAccount = () => {
    handleClickOpen();
    document.body.style.overflow = "hidden";
  };

  const handlePasswordChange = () => {
    handleClickOpenChangePassword();
    document.body.style.overflow = "hidden";
  };

  const handleBioChange = () => {
    handleOpenBio();
    document.body.style.overflow = "hidden";
  };

  const handleOpenPaymentCard = () => {
    handleClickOpenAddCard();
    document.body.style.overflow = "hidden";
  };

  const handleEditprofile = () => {
    navigate("/edit-profile", { state: { pathname: "givehelp" } });
  };

  const processImages = async (files) => {
    if (selectedImages.length + files.length > 5) {
      Toaster("error", "Maximum 5 images are allowed");
      return;
    }

    const formData = new FormData();
    files.forEach((img) => formData.append("profileImages", img));

    setIsLoading(true);
    try {
      const response = await UserService.updateProfileImages(formData);
      getOwnProfile();
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }

    const fileURLs = files.map((img) => URL.createObjectURL(img));
    setSelectedImages((prev) => [...prev, ...fileURLs]);
  };

  const handleChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    await processImages(files);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);

    await processImages(files);
  };

  const getOwnProfile = async () => {
    setIsLoading(true);
    try {
      const response = await UserService.getOwnProfile(token);
      setData(response.data.data);
      setSelectedImages(response.data.data.profileImages);
      formik.setFieldValue("bio", response.data.data.bio);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOutsideClick = (event) => {
    if (passwordRef.current && !passwordRef.current.contains(event.target)) {
      handleClickCloseChangePassword();
      document.body.style.overflow = "unset";
    }
    if (helpRef.current && !helpRef.current.contains(event.target)) {
      handleClickCloseAddCard();
      document.body.style.overflow = "unset";
    }
    if (accountRef.current && !accountRef.current.contains(event.target)) {
      handleClickClose();
      document.body.style.overflow = "unset";
    }
    if (bioRef.current && !bioRef.current.contains(event.target)) {
      handleCloseBio();
      document.body.style.overflow = "unset";
    }
  };

  const removeDeleteSetPreference = async (type, id) => {
    try {
      if (type === "image") {
        setDeleteLoader(true);
        setDeleteIndex(id);
        const images = selectedImages.filter((_, i) => i === id);

        const formData = new FormData();
        images.map((img) => formData.append("deletedImages", img));

        await UserService.updateProfileImages(formData);
      } else if (type === "card") {
        setPreferId(id);
        const response = await StripeService.setCardPreference(id);
        Toaster("success", response.data.message);
      } else if (type === "deletecard") {
        setCardDeleteLoading(id);
        const response = await StripeService.deleteCard(id);

        Toaster("success", "Card deleted Successfully!");
      } else if (type === "account") {
        setPreferId(id);
        const response = await StripeService.setAccountPreference(id);
        Toaster("success", "Set Preference sucessfully");
      }
    } catch (error) {
      if (type === "deletecard") {
        Toaster("error", error.response.data.raw.message);
      } else {
        Toaster("error", error.response.data.message);
      }
    } finally {
      if (type === "account" || type === "card") {
        setPreferId("");
      } else if (type === "deletecard") {
        setCardDeleteLoading("");
      } else if (type === "image") {
        setDeleteLoader(false);
      }
      getOwnProfile();
    }
  };

  const formik = useFormik({
    initialValues: {
      bio: "",
    },
    validationSchema: yup.object().shape({
      bio: yup.string().required("Bio is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await UserService.updateBio(values.bio);
        Toaster("success", response.data.message);
        handleCloseBio();
        getOwnProfile();
        handleScrollChange();
      } catch (error) {
        Toaster("error", error.response.data.message);
      }
    },
  });

  useEffect(() => {
    scrollToTop();
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    getOwnProfile();
  }, []);

  const closePassword = () => {
    handleClickCloseChangePassword();
    handleScrollChange();
  };

  const closeAccount = () => {
    handleClickClose();
    handleScrollChange();
  };

  const closeHelpCard = () => {
    handleClickCloseAddCard();
    handleScrollChange();
  };

  const closeBio = () => {
    handleCloseBio();
    getOwnProfile();
    formik.resetForm();
    handleScrollChange();
  };

  const handleScrollChange = () => {
    document.body.style.overflow = "unset";
  };

  const cardFormik = useFormik({
    initialValues: {
      holdername: "",
      cardnumber: "",
      expiry: "",
      cvc: "",
      isPreferred: false,
    },
    validationSchema: yup.object().shape({
      holdername: yup.string().required("Holder Name is required"),
    }),
    onSubmit: async (values) => {
      setCardIsLoading(true);
      try {
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: values.holdername,
          },
        });

        const body = {
          customerId: data.customerId,
          paymentMethodId: paymentMethod.id,
          isPreferred: values.isPreferred,
        };
        const response = await StripeService.addCard(body);
        closeHelpCard();
        cardFormik.resetForm();
        getOwnProfile();
        Toaster("success", response.data.message);
      } catch (error) {
        Toaster("error", error.response.data.message);
      } finally {
        setCardIsLoading(false);
      }
    },
  });

  const accountFormik = useFormik({
    initialValues: {
      customerId: "",
      accountHolderName: "",
      branchCode: "",
      accountNo: "",
      isPreferred: false,
    },
    onSubmit: async (values) => {
      setAccountIsLoading(true);
      try {
        const { token, error } = await stripe.createToken("bank_account", {
          country: "US",
          currency: "usd",
          account_holder_name: values.accountHolderName,
          account_number: String(values.accountNo)
            .replace(/^0+/, "")
            .padStart(12, "0"),
          routing_number: values.branchCode,
          account_holder_type: "individual",
        });

        const body = {
          customerId: data.customerId,
          accountHolderName: values.accountHolderName,
          branchCode: values.branchCode,
          accountNo: String(values.accountNo)
            .replace(/^0+/, "")
            .padStart(12, "0"),
          isPreferred: values.isPreferred,
        };

        const response = await StripeService.addAccount(body);
        getOwnProfile();
        closeAccount();
        Toaster("success", response.data.message);
        accountFormik.resetForm();
      } catch (error) {
        Toaster("error", error.response.data.raw.message);
      } finally {
        setAccountIsLoading(false);
      }
    },
  });

  return (
    <div className="dashboard-part flex lg:gap-9 min-h-[calc(100vh-195px)]">
      {/* Dashboard Total Uer Card */}

      <div className="dashboard-card w-full">
        <div className="p-4 tab:px-26 tab:py-5 b-b">
          <h6 className="text-base">My Profile</h6>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <img src={Loader} alt="Loader" className="h-16 my-3" />
          </div>
        ) : (
          <>
            <div className="px-5 tab:px-26">
              <div className="py-5 tab:py-26 flex flex-col gap-26 b-b">
                <div className="flex justify-between gap-4">
                  <div className="flex items-center gap-4">
                    <div className="h-10 w-10 tab:h-[60px] tab:w-[60px] rounded-full overflow-hidden avatar">
                      {data.latestProfileImage ? (
                        <img
                          src={data.latestProfileImage}
                          alt="Profile"
                          className="w-full h-full bg-center bg-cover object-cover"
                        />
                      ) : (
                        <AvatarIcon
                          size={60}
                          className="rounded-full"
                          name={data?.name}
                        />
                      )}
                    </div>

                    <h5 className="text-base tab:text-26">{data?.name}</h5>
                  </div>
                  <div>
                    <button
                      className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group"
                      onClick={handleEditprofile}
                    >
                      <AiFillEdit className="text-primary text-base group-hover:text-white duration-200" />
                      Edit Profile
                    </button>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2.5 pb-2.5">
                    <AiFillMail className="text-base" />
                    <p>{data.email}</p>
                  </div>
                  {data.address && (
                    <div className="flex items-center gap-2.5 pb-4">
                      <div className="h-4 w-4">
                        <img src={Location} alt="Location" />
                      </div>
                      <p>{data.address}</p>
                    </div>
                  )}
                  <div className="flex items-center pb-4 whitespace-nowrap">
                    {data.isIncomeVerified && (
                      <>
                        <div className="flex items-center gap-2.5 border-r border-primary pe-2.5 tab:pe-4">
                          <img src={VerifyBlue} alt="Verify" />
                          <strong className="text-10 tab:text-xs">
                            Income Verified
                          </strong>
                        </div>
                        <div className="ps-2.5 tab:ps-4">
                          <p className="text-10 tab:text-xs">
                            Get Help Eligibility
                          </p>
                        </div>
                      </>
                    )}

                    {data.isPayyroVerified && (
                      <div className="flex items-center gap-1 tab:gap-2.5 ps-1.5">
                        <img src={VerifyGreen} alt="Verify" />
                        <strong className="text-10 tab:text-xs">
                          Payyro Verified
                        </strong>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-2.5 tab:gap-4">
                  <div className="flex items-center gap-1.5">
                    <FaUserLarge className="text-primary text-base" />
                    <strong className="text-xs tab:text-base">
                      Account Type
                    </strong>
                  </div>
                  <span class="py-1 px-3 bg-blue/10 rounded-full">
                    <strong class="text-blue">{data.accountType}</strong>
                  </span>
                </div>
                <div
                  // href=""
                  className="flex items-center gap-1.5 cursor-pointer"
                  onClick={handlePasswordChange}
                >
                  <img src={Lock} alt="Lock" />
                  <strong className="text-xs tab:text-base underline underline-offset-4">
                    Change Password
                  </strong>
                </div>
              </div>
              {data.bio && (
                <div className="py-5 tab:py-26 b-b">
                  <div className="flex justify-between gap-4 items-center tab:items-start">
                    <h6 className="text-sm tab:text-lg">About Me</h6>
                    <button
                      className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 group"
                      onClick={handleBioChange}
                    >
                      <AiFillEdit className="text-primary text-base group-hover:text-white duration-200" />
                      Edit Bio
                    </button>
                  </div>
                  <p className="pt-4 tab:pt-26 text-xs tab:text-sm">
                    {data.bio}
                  </p>
                </div>
              )}
              <div className="py-5 tab:py-26 b-b">
                <div className="flex justify-between gap-2 items-center tab:items-start pb-4 tab:pb-26">
                  <div>
                    <h6 className="text-sm tab:text-lg">Profile Images</h6>
                    <p className="text-xs tab:text-13">
                      (Max. 5 images allowed)
                    </p>
                  </div>
                  <label
                    htmlFor="upload"
                    className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group cursor-pointer"
                    type="button"
                  >
                    <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
                    Add Image
                  </label>
                </div>
                <div className="flex items-center gap-4">
                  {selectedImages &&
                    selectedImages?.map((img, index) => {
                      return (
                        <div
                          className="w-[140px] h-[140px] relative"
                          key={index}
                        >
                          <img
                            src={img}
                            alt=""
                            className="rounded-10 w-full h-full object-cover"
                          />
                          <div
                            className="w-8 h-8 bg-white/80 absolute top-2 right-2 rounded-10 cursor-pointer"
                            onClick={() =>
                              removeDeleteSetPreference("image", index)
                            }
                          >
                            {deleteLoader ? (
                              <>
                                {deleteIndex === index ? (
                                  <img src={Loader} alt="Loader" />
                                ) : (
                                  <AiFillDelete className="text-red-500 text-17 content-center" />
                                )}
                              </>
                            ) : (
                              <AiFillDelete className="text-red-500 text-17 content-center" />
                            )}
                          </div>
                        </div>
                      );
                    })}

                  {selectedImages.length < 5 && (
                    <div
                      className="bg-primary/10 w-[140px] h-[140px] rounded-10 flex items-center p-1"
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => handleDrop(e)}
                    >
                      <div className="mx-auto">
                        <label
                          htmlFor="upload"
                          className="w-full flex flex-col justify-center items-center"
                        >
                          <div className="m-4 mt-0 w-8 h-8 bg-white/60 rounded-lg flex justify-center items-center">
                            <LuUpload className="text-17" />
                          </div>
                          <div className="text-center">
                            Browse or Drag image to upload
                          </div>
                        </label>
                      </div>
                    </div>
                  )}

                  <input
                    type="file"
                    multiple
                    onChange={(e) => handleChangeFile(e)}
                    hidden
                    id="upload"
                  />
                </div>
              </div>
              <div className="py-5 tab:py-26 b-b">
                <div className="flex justify-between gap-4 items-center tab:items-start">
                  <h6 className="text-sm tab:text-lg">Bank Details</h6>
                  <button
                    className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group"
                    onClick={handleAddAccount}
                  >
                    <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
                    Add Account
                  </button>
                </div>
                <p className="text[13px] pt-4 tab:pt-26">
                  <strong>Note: </strong>Bank detail is needed only when you are
                  giving help.
                </p>

                <div className="profile-bank-card">
                  {data?.bankaccounts != "" ? (
                    data?.bankaccounts?.map((item, index) => {
                      return (
                        <div
                          className="flex justify-start items-start gap-2.5 mt-[26px]"
                          key={index}
                        >
                          <div className="flex justify-start items-start gap-2.5">
                            <div>
                              <img src={citi} />
                            </div>
                            <div>
                              <h6 className="text-base">{item.bankName}</h6>

                              <p className="text-sm tab:text-xs">
                                Account ending with {item.last4}
                              </p>
                              <div className="flex justify-start items-center mt-2.5">
                                {item.isPreferred ? (
                                  <h6 className="text-xs text-success bg-success/15 rounded-full px-5 py-1.5">
                                    Preferred
                                  </h6>
                                ) : (
                                  <>
                                    {preferId === item._id ? (
                                      <h6 className="text-xs text-primary bg-primary/15 rounded-full px-5 py-1.5 cursor-pointer w-[124.05px] flex justify-center items-center h-[28px]">
                                        <img src={Loader} className="h-6 w-6" />
                                      </h6>
                                    ) : (
                                      <h6
                                        className="text-xs text-primary bg-primary/15 rounded-full px-5 py-1.5 cursor-pointer"
                                        onClick={() =>
                                          removeDeleteSetPreference(
                                            "account",
                                            item._id
                                          )
                                        }
                                      >
                                        Set Preference
                                      </h6>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <p className="mt-2 text-lg">Account Not Found</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="py-5 tab:py-26 b-b">
                <div className="flex justify-between gap-4 items-center tab:items-start">
                  <h6 className="text-sm tab:text-lg">Card Details</h6>
                  <button
                    className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group"
                    onClick={handleOpenPaymentCard}
                  >
                    <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
                    Add Card
                  </button>
                </div>
                <p className="text[13px] pt-4 tab:pt-26">
                  <strong>Note: </strong>Card detail is needed only when you are
                  giving help.
                </p>
                <div className="profile-bank-card">
                  {data?.cards != "" ? (
                    data?.cards?.map((item, index) => {
                      return (
                        <div
                          className="flex justify-between items-start border border-primary/20 w-fit rounded-lg p-2.5 mt-[26px]"
                          key={index}
                        >
                          <div className="flex justify-start items-start gap-2.5">
                            <div>
                              <img src={citi} />
                            </div>
                            <div>
                              <h6 className="text-base">
                                Citi Bank Credit Card
                              </h6>
                              <div className="flex justify-start items-center gap-1">
                                {/* <img src={visa} /> */}
                                {item.display_brand}
                                <p className="text-sm">
                                  card ending with {item.last4}
                                </p>
                              </div>
                              <div className="flex justify-start items-center mt-2.5">
                                {item.isPreferred ? (
                                  <h6 className="text-xs text-success bg-success/15 rounded-full px-5 py-1.5">
                                    Preferred
                                  </h6>
                                ) : (
                                  <>
                                    {preferId === item._id ? (
                                      <h6 className="text-xs text-primary bg-primary/15 rounded-full px-5 py-1.5 cursor-pointer w-[124.05px] flex justify-center items-center h-[28px]">
                                        <img src={Loader} className="h-6 w-6" />
                                      </h6>
                                    ) : (
                                      <h6
                                        className="text-xs text-primary bg-primary/15 rounded-full px-5 py-1.5 cursor-pointer"
                                        onClick={() =>
                                          removeDeleteSetPreference(
                                            "card",
                                            item._id
                                          )
                                        }
                                      >
                                        Set Preference
                                      </h6>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div>
                              {cardDeleteLoading === item._id ? (
                                <img src={Loader} className="h-4 w-4" />
                              ) : (
                                <IoCloseOutline
                                  className="h-4 w-4 cursor-pointer"
                                  onClick={() =>
                                    removeDeleteSetPreference(
                                      "deletecard",
                                      item._id
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <p className="mt-2 text-lg">Card Not Found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {open && (
          <AddAccount
            handleClickClose={closeAccount}
            open={open}
            accountRef={accountRef}
            accountFormik={accountFormik}
            accountIsLoading={accountIsLoading}
          />
        )}
        {openChangePassword && (
          <ChangePassword
            open={openChangePassword}
            handleClickClose={closePassword}
            passwordRef={passwordRef}
            type="user"
          />
        )}
        {openAddCard && (
          <AddGiveHelpCard
            open={openAddCard}
            handleClickClose={closeHelpCard}
            helpRef={helpRef}
            cardFormik={cardFormik}
            cardIsLoading={cardIsLoading}
          />
        )}
        {bio && (
          <UserBio
            bio={bio}
            handleCloseBio={closeBio}
            bioRef={bioRef}
            formik={formik}
          />
        )}
      </div>
    </div>
  );
};

export default GiveHelpUserProfile;
