import React from "react";
import { LuUpload } from "react-icons/lu";
import SlashInput from "../../common/SlashInput";
import { IoLocation } from "react-icons/io5";
import Textarea from "../../common/textarea";
import { AiFillCamera, AiFillDelete } from "react-icons/ai";
import Loader from "../../../assets/loader.gif";
import { MdOutlineAdd } from "react-icons/md";
import Profile from "../../../assets/profile.jpg";

const SignUp2 = ({
  formik,
  selectedImages,
  deleteImages,
  handleDrop,
  handleChangeFile,
  deleteLoader,
  deleteIndex,
  handleLatestFileChnage,
  selectedLatestProfile,
}) => {
  return (
    <div className="my-4 flex flex-col gap-3">
      <div className="mb-2">
        <div
          className="block tab:flex tab:text-start text-center justify-center tab:justify-start items-center tab:gap-9"
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e, "latestProfile")}
        >
          <div className="relative tab:h-[100px] tab:w-[100px] h-20 w-20 mx-auto tab:mx-0 mb-5 tab:mb-0">
            <img
              src={selectedLatestProfile ? selectedLatestProfile : Profile}
              alt="Profile"
              className="h-full w-full rounded-full object-cover"
            />
            <label
              htmlFor="profile-upload"
              className="absolute bottom-1 -right-1  h-[22px] w-[22px] tab:h-9 tab:w-9 rounded-full flex justify-center items-center bg-primary cursor-pointer"
            >
              <AiFillCamera className="text-white text-xl" />
              <input
                id="profile-upload"
                onChange={handleLatestFileChnage}
                type="file"
                className="hidden"
              />
            </label>
          </div>
          <div>
            <h6 className="tab:text-lg text-base ">Profile Photo</h6>
            <p className="text-sm">Browse or Drag image to upload</p>
          </div>
        </div>
        {formik.errors.latestProfileImage ? (
          <div className="valierror mt-2">
            {formik.errors.latestProfileImage}
          </div>
        ) : null}
      </div>

      <div>
        <label>Address*</label>
        <div className="relative">
          <SlashInput
            formik={formik}
            name="address"
            type="text"
            placeholder="|"
          />
          <div className="absolute top-1/2 right-3 -translate-y-1/2">
            <IoLocation className="text-primary/50" />
          </div>
        </div>
        {formik.errors.address ? (
          <div className="valierror">{formik.errors.address}</div>
        ) : null}
      </div>

      <div>
        <label>Bio*</label>
        <div className="relative">
          <Textarea name="bio" type="text" placeholder="|" formik={formik} />
        </div>
        {formik.errors.bio ? (
          <div className="valierror">{formik.errors.bio}</div>
        ) : null}
      </div>

      <div className="flex justify-between gap-2 items-center tab:items-start pb-4 tab:pb-3">
        <div>
          <h6 className="text-sm tab:text-lg">Profile Images</h6>
          <p className="text-xs tab:text-13">(Max. 5 images allowed)</p>
        </div>
        <label
          htmlFor="upload"
          className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group cursor-pointer"
          type="button"
        >
          <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
          Add Image
        </label>
      </div>

      <div className="flex items-center gap-4">
        {selectedImages &&
          selectedImages?.map((img, index) => {
            return (
              <div className="w-[120px] h-[120px] relative" key={index}>
                <img
                  src={img}
                  alt=""
                  className="rounded-10 w-full h-full object-cover"
                />
                <div
                  className="w-8 h-8 bg-white/80 absolute top-2 right-2 rounded-10 cursor-pointer"
                  onClick={() => deleteImages(index)}
                >
                  {deleteLoader ? (
                    <>
                      {deleteIndex === index ? (
                        <img src={Loader} alt="Loader" />
                      ) : (
                        <AiFillDelete className="text-red-500 text-17 content-center" />
                      )}
                    </>
                  ) : (
                    <AiFillDelete className="text-red-500 text-17 content-center" />
                  )}
                </div>
              </div>
            );
          })}

        {selectedImages.length < 5 && (
          <div
            className="bg-primary/10 w-[120px] h-[120px] rounded-10 flex items-center p-1"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleDrop(e, "profiles")}
          >
            <div className="mx-auto">
              <label
                htmlFor="upload"
                className="w-full flex flex-col justify-center items-center"
              >
                <div className="m-4 mt-0 w-8 h-8 bg-white/60 rounded-lg flex justify-center items-center">
                  <LuUpload className="text-17" />
                </div>
                <div className="text-center">
                  Browse or Drag image to upload
                </div>
              </label>
            </div>
          </div>
        )}

        <input
          type="file"
          multiple
          onChange={(e) => handleChangeFile(e)}
          hidden
          id="upload"
        />
      </div>
      {formik.errors.profileImages ? (
        <div className="valierror mt-2">{formik.errors.profileImages}</div>
      ) : null}
    </div>
  );
};

export default SignUp2;
