import ServerCall from "../../serverCall";

const serverCall = ServerCall();
const getOwnVendorProfile = () => {
  //   const response = serverCall.customAxios.get(`/users/get-own-profile`);
  //   return response;
};

const changeVendorPassword = (data) => {
  const response = serverCall.customAxios.patch(
    `/vendor/change-password`,
    data
  );
  return response;
};

const getAllVendor = () => {
  const response = serverCall.customAxios.get(`/vendor/get-all-vendor`);
  return response;
};

const getDashboard = (body) => {
  const response = serverCall.customAxios.post(`/vendor/dashboard`, body);
  return response;
};

const getRequestDetails = (requestId) => {
  const response = serverCall.customAxios.get(
    `/help-request/vendor/get-request-detail?requestId=${requestId}`
  );
  return response;
};

const unpublishHelpRequest = (helpRequestId) => {
  const response = serverCall.customAxios.post(
    `/help-request/vendor/unpublish-help-request?helpRequestId=${helpRequestId}`
  );
  return response;
};

const vendorGetAllUSer = () => {
  const response = serverCall.customAxios.get(`/users/vendor/get-all-user`);
  return response;
};

const getVendorOwnProfile = () => {
  const response = serverCall.customAxios.get(`/vendor/get-profile`);
  return response;
};

const updatePersonalDetails = (body) => {
  const response = serverCall.customAxios.patch(
    `/vendor/update-personal-detail`,
    body
  );
  return response;
};

const createBulkHelpRequest = (body) => {
  const response = serverCall.customAxios.post(
    `/help-request/vendor/create-bulk-request`,
    body
  );
  return response;
};

const updateHelpRequestStatus = (requestId, status) => {
  const response = serverCall.customAxios.post(
    `/help-request/vendor/update-request?requestId=${requestId}&status=${status}`
  );
  return response;
};

const VendorService = {
  getOwnVendorProfile,
  changeVendorPassword,
  getAllVendor,
  getDashboard,
  getRequestDetails,
  unpublishHelpRequest,
  vendorGetAllUSer,
  getVendorOwnProfile,
  updatePersonalDetails,
  createBulkHelpRequest,
  updateHelpRequestStatus,
};

export default VendorService;
