import React, { useEffect, useRef, useState } from "react";
import DashboardSidebar from "../../layout/DashboardSidebar";
import { AiFillEdit } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";
import { IoAdd } from "react-icons/io5";
import { AiFillLock } from "react-icons/ai";

import Location from "../../../assets/svg/location.svg";
import citi from "../../../assets/svg/citi.svg";
import AddAccount from "../../common/AddAccount";
import UseDialogState from "../../../hooks/UseDialogState";
import ChangePassword from "../../common/ChangePassword";
import { useNavigate } from "react-router-dom";
import Toaster from "../../common/toaster";
import VendorService from "../../service/vendor.service";
import { scrollToTop } from "../../common/constatnts";
import AvatarIcon from "../../common/Avatar";
import Loader from "../../../assets/loader.gif";
import StripeService from "../../service/stripe.service";
import { useFormik } from "formik";
import { useStripe } from "@stripe/react-stripe-js";

const VendorProfile = () => {
  const {
    open,
    handleClickOpen,
    handleClickClose,
    openChangePassword,
    handleClickCloseChangePassword,
    handleClickOpenChangePassword,
  } = UseDialogState();
  const navigate = useNavigate();
  const stripe = useStripe();
  const passwordRef = useRef();
  const helpRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [accountIsLoading, setAccountIsLoading] = useState(false);
  const [preferId, setPreferId] = useState("");

  const handleClickAddAcccount = () => {
    handleClickOpen();
    document.body.style.overflow = "hidden";
  };

  const handlePasswordChange = () => {
    handleClickOpenChangePassword();
    document.body.style.overflow = "hidden";
  };

  const getOwnProfile = async () => {
    setIsLoading(true);
    try {
      const response = await VendorService.getVendorOwnProfile();
      setData(response.data.data);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const closePassword = () => {
    handleClickCloseChangePassword();
    handleScrollChange();
  };

  const handleScrollChange = () => {
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    getOwnProfile();
    scrollToTop();
  }, []);

  const handleOpenEditProfile = () => {
    navigate("/edit-profile", { state: { pathname: "vendor" } });
  };

  const handleOutsideClick = (event) => {
    if (passwordRef.current && !passwordRef.current.contains(event.target)) {
      handleClickCloseChangePassword();
      document.body.style.overflow = "unset";
    }
    if (helpRef.current && !helpRef.current.contains(event.target)) {
      handleClickClose();
      document.body.style.overflow = "unset";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const closeAccount = () => {
    handleClickClose();
    handleScrollChange();
  };

  const formik = useFormik({
    initialValues: {
      customerId: "",
      accountHolderName: "",
      branchCode: "",
      accountNo: "",
      isPreferred: false,
    },
    onSubmit: async (values) => {
      setAccountIsLoading(true);
      try {
        const { token, error } = await stripe.createToken("bank_account", {
          country: "US",
          currency: "usd",
          account_holder_name: values.accountHolderName,
          account_number: String(values.accountNo)
            .replace(/^0+/, "")
            .padStart(12, "0"),
          routing_number: values.branchCode,
          account_holder_type: "individual",
        });

        const body = {
          customerId: data.customerId,
          accountHolderName: values.accountHolderName,
          branchCode: values.branchCode,
          accountNo: String(values.accountNo)
            .replace(/^0+/, "")
            .padStart(12, "0"),
          isPreferred: values.isPreferred,
        };

        const response = await StripeService.addAccount(body);
        getOwnProfile();
        closeAccount();
        Toaster("success", response.data.message);
        formik.resetForm();
      } catch (error) {
        Toaster("error", error.response.data.raw.message);
      } finally {
        setAccountIsLoading(false);
      }
    },
  });

  const setAccountPreference = async (id) => {
    try {
      setPreferId(id);
      await StripeService.setAccountPreference(id);
      Toaster("success", "Set Preference sucessfully");
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setPreferId("");
      getOwnProfile();
    }
  };

  return (
    <div className="dashboard-part flex min-h-[calc(100vh-195px)]">
      <div className="dashboard-card tab:max-w-[758px] tab:min-w-[758px] w-full">
        <h6 className="text-sm tab:text-base tab:py-5 tab:px-26 py-4 px-4 b-b border-primary/20">
          My Profile
        </h6>
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <img src={Loader} alt="Loader" className="h-16 my-3" />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-[26px] p-26">
              <div className="flex justify-between items-start">
                <div className="flex justify-start items-center gap-1 tab:gap-4">
                  <div className="h-10 w-10 tab:h-[60px] tab:w-[60px] rounded-full overflow-hidden avatar">
                    {data.latestProfileImage ? (
                      <img
                        src={data.latestProfileImage}
                        alt="Profile"
                        className="w-full h-full bg-center bg-cover object-cover"
                      />
                    ) : (
                      <AvatarIcon
                        size={60}
                        className="rounded-full"
                        name={data?.name}
                      />
                    )}
                  </div>
                  <div>
                    <h5 className="tab:text-xl text-base">{data?.name}</h5>
                    <p className="text-sm tab:text-base">{data?.company}</p>
                  </div>
                </div>
                <div>
                  <button
                    className="btn-default btn-outline-primary flex justify-center items-center gap-1"
                    onClick={handleOpenEditProfile}
                  >
                    <AiFillEdit className="h-4 w-4" />
                    Edit Profile
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-2.5">
                <div className="flex justify-start items-center gap-2.5">
                  <AiFillMail className="h-4 w-4" />
                  <p className="text-sm">{data?.email}</p>
                </div>
                <div className="flex justify-start items-center gap-2.5">
                  <img src={Location} alt="Location" />
                  {/* <IoLocation className="h-4 w-4" /> */}
                  <p className="text-sm">{data?.address}</p>
                </div>
              </div>
              <div
                className="flex justify-start items-center gap-1.5 cursor-pointer"
                onClick={() => handlePasswordChange()}
              >
                <AiFillLock className="tab:text-xl text-base" />
                <h6 className="text-xs tab:text-base underline underline-offset-2">
                  Change Password
                </h6>
              </div>
              <div className="b-b border-primary/20"></div>
              <div>
                <div className="flex justify-between items-center">
                  <h6 className="tab:text-lg text-sm">Bank Details</h6>
                  <div>
                    <button
                      className="btn-default btn-outline-primary flex justify-center items-center gap-1"
                      onClick={() => handleClickAddAcccount()}
                    >
                      <IoAdd className="text-base" />
                      Add Account
                    </button>
                  </div>
                </div>
                <div className="mt-2 tab:mt-0">
                  <strong className="text-[13px]">
                    Note:{" "}
                    <span className="font-normal">
                      Bank detail is needed only when you are giving help.
                    </span>
                  </strong>
                </div>
              </div>

              <div className="profile-bank-card">
                {data?.bankaccounts != "" ? (
                  data?.bankaccounts?.map((item, index) => {
                    return (
                      <div
                        className="flex justify-start items-start gap-2.5 mt-[26px]"
                        key={index}
                      >
                        <div className="flex justify-start items-start gap-2.5">
                          <div>
                            <img src={citi} />
                          </div>
                          <div>
                            <h6 className="text-base">{item.bankName}</h6>

                            <p className="text-sm tab:text-xs">
                              Account ending with {item.last4}
                            </p>
                            <div className="flex justify-start items-center mt-2.5">
                              {item.isPreferred ? (
                                <h6 className="text-xs text-success bg-success/15 rounded-full px-5 py-1.5">
                                  Preferred
                                </h6>
                              ) : (
                                <>
                                  {preferId === item._id ? (
                                    <h6 className="text-xs text-primary bg-primary/15 rounded-full px-5 py-1.5 cursor-pointer w-[124.05px] flex justify-center items-center h-[28px]">
                                      <img src={Loader} className="h-6 w-6" />
                                    </h6>
                                  ) : (
                                    <h6
                                      className="text-xs text-primary bg-primary/15 rounded-full px-5 py-1.5 cursor-pointer"
                                      onClick={() =>
                                        setAccountPreference(item._id)
                                      }
                                    >
                                      Set Preference
                                    </h6>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p className="mt-2 text-lg">Account Not Found</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {open && (
        <AddAccount
          handleClickClose={closeAccount}
          open={open}
          accountRef={helpRef}
          accountFormik={formik}
          accountIsLoading={accountIsLoading}
        />
      )}

      {openChangePassword && (
        <ChangePassword
          open={openChangePassword}
          handleClickClose={closePassword}
          passwordRef={passwordRef}
          type="vendor"
        />
      )}
    </div>
  );
};

export default VendorProfile;
