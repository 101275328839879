import moment from "moment";

export const LocalData = () => {
  const payrroString = localStorage.getItem("payyro-store");
  const payyroStore = payrroString && JSON.parse(payrroString);
  return payyroStore;
};

export const AmountDropDown = [
  { label: "Less Than 200", value: "lessThan200" },
  { label: "200 To 500", value: "200To500" },
  { label: "More Than 500", value: "moreThan500" },
  { label: "Any", value: "any" },
];

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const formatUploadTime = (milliseconds) => {
  const now = moment();
  const uploadTime = moment(milliseconds);
  const duration = moment.duration(now.diff(uploadTime));
  const hoursDifference = duration.asHours();

  if (hoursDifference < 24) {
    return uploadTime.fromNow();
  } else if (hoursDifference >= 24 && hoursDifference < 48) {
    return "1 day ago";
  } else {
    return uploadTime.format("DD MMM, YYYY");
  }
};

export const NumberWithCommas = (number) => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};
