import React from "react";
import { LuUpload } from "react-icons/lu";
import { MdOutlineAdd } from "react-icons/md";
import vimg from "../../../assets/svg/vimg.png";
import { IoMdCloseCircle } from "react-icons/io";
import { AiFillFileExcel } from "react-icons/ai";
import { BsFileEarmarkPdfFill, BsFileEarmarkWordFill } from "react-icons/bs";
import { BiSolidFileJpg, BiSolidFilePng } from "react-icons/bi";
import { formatFileSize } from "../../common/Common";

const SignUp3 = ({
  handleAddDocument,
  selectedDocuments,
  deleteDocument,
  formik,
  handleEditVideo,
  selectedVideo,
}) => {
  return (
    <div className="my-4">
      <div>
        <div className="flex justify-between gap-2 items-center tab:items-start pb-26">
          <h6 className="text-sm tab:text-lg">Income Details</h6>
          <button
            className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group"
            onClick={handleAddDocument}
            type="button"
          >
            <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
            Add Documents
          </button>
        </div>
        {selectedDocuments == "" && <div>Documents Not Found</div>}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
          {selectedDocuments != ""
            ? selectedDocuments?.map((file, index) => {
                return (
                  <div
                    className="flex justify-between items-start border border-primary/20 w-full rounded-lg p-2.5"
                    key={index}
                  >
                    <div className="flex justify-start items-start gap-2.5">
                      <div
                        className={`${
                          file.name?.endsWith(".pdf")
                            ? "text-red-500"
                            : file.name?.endsWith(".xls") ||
                              file.name?.endsWith(".xlsx")
                            ? "text-green-500"
                            : file.name?.endsWith(".jpg") ||
                              file.name?.endsWith(".jpeg")
                            ? "text-purple-400"
                            : file.name?.endsWith(".png") ||
                              file.name?.endsWith(".png")
                            ? "text-yellow"
                            : "text-blue"
                        } text-2xl`}
                      >
                        {file.name?.endsWith(".pdf") ? (
                          <BsFileEarmarkPdfFill />
                        ) : file.name?.endsWith(".xls") ||
                          file.name?.endsWith(".xlsx") ? (
                          <AiFillFileExcel />
                        ) : file.name?.endsWith(".jpg") ||
                          file.name?.endsWith(".jpeg") ? (
                          <BiSolidFileJpg />
                        ) : file.name?.endsWith(".png") ||
                          file.name?.endsWith(".png") ? (
                          <BiSolidFilePng />
                        ) : (
                          <BsFileEarmarkWordFill />
                        )}
                      </div>
                      <div>
                        <h6 className="text-base break-all">{file.name}</h6>
                        <p className="text-sm">{formatFileSize(file.size)}</p>
                      </div>
                    </div>
                    <div>
                      <IoMdCloseCircle
                        className="h-4 w-4 text-primary/40 cursor-pointer"
                        onClick={() => deleteDocument(index)}
                      />
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        {formik.touched.document && formik.errors.document ? (
          <div className="valierror mt-2">{formik.errors.document}</div>
        ) : null}
      </div>

      <div className="py-5 tab:py-26">
        <div className="flex justify-between gap-4 items-center tab:items-start">
          <div>
            <h6 className="text-sm tab:text-lg">
              My video for Donors in support of my Help Requests
            </h6>
            <p>(Max. 60 seconds allowed)</p>
          </div>
          <label
            htmlFor="uploadvideo"
            className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group cursor-pointer"
          >
            <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
            Add Video
          </label>
          <input
            type="file"
            onChange={(e) => handleEditVideo(e)}
            hidden
            id="uploadvideo"
            accept="video/*"
          />
        </div>
        {selectedVideo && (
          <div className="mt-4 max-w-[70%]">
            <video controls autoPlay className="rounded-xl cursor-pointer">
              <source src={selectedVideo}></source>
            </video>
          </div>
        )}
        {/* <div className="mt-4">
          <img src={vimg} />
        </div> */}
        {formik.touched.video && formik.errors.video ? (
          <div className="valierror">{formik.errors.video}</div>
        ) : null}
      </div>
    </div>
  );
};

export default SignUp3;
