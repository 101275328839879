import React, { useEffect, useRef, useState } from "react";
import UserCard from "../../common/UserCard";
import { RiMapPinFill } from "react-icons/ri";
import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { CgSearch } from "react-icons/cg";
import DropdownList from "../../common/dropdown";
import CustomPagination from "../../common/Pagination";
import { AiFillFolderOpen } from "react-icons/ai";
import "../../user/gethelp/table.css";
import User from "../../../assets/svg/user/user-1.png";
import UseDialogState from "../../../hooks/UseDialogState";
import HelpByMeCard from "./HelpByMeCard";
import HelpService from "../../service/help.service";
import Toaster from "../../common/toaster";
import CategoryService from "../../service/category.service";
import VendorService from "../../service/vendor.service";
import moment from "moment";
import AvatarIcon from "../../common/Avatar";
import Loader from "../../../assets/loader.gif";
import { useSelector } from "react-redux";
import { scrollToTop } from "../../common/constatnts";
import { AmountDropDown } from "../../common/Common";
import PaymentReceipt from "../../common/PaymentReceipt";

const UserGiveHelp = () => {
  const PAGE_LIMIT_HELP_REQUEST = 8;
  const { token } = useSelector((state) => state.auth);
  const { open, handleClickOpen, handleClickClose } = UseDialogState();
  const helpRef = useRef();
  const [showToggleField, setShowToggleField] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [helpPage, setHelpPage] = useState(1);

  const [helpData, setHelpData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [vendor, setVendor] = useState("");
  const [amountRequested, setAmountRequested] = useState("");
  const [location, setLocation] = useState("");

  const [helpedByMeData, setHelpedByMeData] = useState([]);
  const [helpSearch, setHelpSearch] = useState("");
  const [helpCategory, setHelpCategory] = useState("");
  const [helpVendor, setHelpVendor] = useState("");
  const [helpDonatedAmount, setHelpDonatedAmount] = useState("");
  const [helpSingleData, setHelpSingleData] = useState([]);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [totalHelpCount, setTotalHelpCount] = useState(0);
  const [loadMoreClick, setLoadMoreClick] = useState(false);
  const [isHelpByMeLoading, setIsHelpByMeLoading] = useState(true);
  const [rowLoading, setRowLoading] = useState(null);

  const getAllHelpRequest = async () => {
    if (loadMoreClick) {
      setIsLoadMoreLoading(true);
    }
    try {
      const body = {
        search: searchText,
        category: category,
        vendor: vendor,
        requestedAmount: amountRequested,
        date: startDate,
        location: location,
        page: helpPage,
        limit: PAGE_LIMIT_HELP_REQUEST,
      };
      const response = await HelpService.getAllHelpRequest(body);
      if (loadMoreClick) {
        setHelpData((prev) => [...prev, ...response.data.data]);
      } else {
        setHelpData(response.data.data);
      }
      setTotalHelpCount(response.data.totalRequest);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
      setIsLoadMoreLoading(false);
      setLoadMoreClick(false);
    }
  };

  const helpedByMe = async () => {
    setIsHelpByMeLoading(true);
    try {
      const body = {
        search: helpSearch,
        category: helpCategory,
        vendor: helpVendor,
        donateAmount: helpDonatedAmount,
        page: page,
        limit: pageSize,
      };
      const response = await HelpService.helpedByMe(body, token);
      setHelpedByMeData(response.data.data);
      setTotalCount(response.data.totalRequest);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsHelpByMeLoading(false);
    }
  };

  const getAllCategory = async () => {
    try {
      const reponse = await CategoryService.getAllCategory();
      const newObject = [
        {
          name: "All",
          _id: "",
        },
        ...reponse.data.data,
      ];
      setCategoryData(newObject);
    } catch (error) {
      Toaster("error", error.response.data.message);
    }
  };

  const getAllVendor = async () => {
    try {
      const reponse = await VendorService.getAllVendor();
      const newObject = [
        {
          name: "All",
          _id: "",
        },
        ...reponse.data.data,
      ];

      setVendorData(newObject);
    } catch (error) {
      Toaster("error", error.response.data.message);
    }
  };

  useEffect(() => {
    getAllHelpRequest();
  }, [
    searchText,
    location,
    startDate,
    amountRequested,
    category,
    vendor,
    helpPage,
  ]);

  useEffect(() => {
    getAllVendor();
    getAllCategory();
  }, []);

  useEffect(() => {
    helpedByMe();
  }, [helpSearch, helpCategory, helpVendor, helpDonatedAmount, page, pageSize]);

  const catelist = [
    ...categoryData.map((cat) => ({
      label: cat.name,
      value: cat._id,
    })),
  ];

  const venList = [
    ...vendorData.map((ven) => ({
      label: ven.name,
      value: ven._id,
    })),
  ];

  const handleSelectDate = (e) => {
    setStartDate(moment(e).format("DD-MM-YYYY"));
  };

  const handleToggleChange = () => {
    setShowToggleField(!showToggleField);
  };

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const handleChange = (type, page) => {
    const total = totalCount / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  const handleHelpByMeOpen = async (item, index) => {
    setRowLoading(index);
    try {
      const response = await HelpService.getActionHelpDetails(item._id);
      setHelpSingleData(response.data.data);
      handleClickOpen();
      document.body.style.overflow = "hidden";
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setRowLoading(null);
    }
  };

  const handleOutsideClick = (event) => {
    if (helpRef.current && !helpRef.current.contains(event.target)) {
      handleClickClose();
      document.body.style.overflow = "unset";
    }
  };

  useEffect(() => {
    scrollToTop();
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleCloseHelp = () => {
    handleClickClose();
    document.body.style.overflow = "unset";
  };

  return (
    <div className="dashboard-part flex lg:gap-9 min-h-[calc(100vh-185px)]">
      {/* Dashboard Total Uer Card */}
      <div className="w-full">
        <h4 className="text-base sm:text-lg pb-4">
          Explore All Active Help Requests...
        </h4>
        <div className="bg-white rounded-lg p-4">
          <div>
            <div className="grid grid-cols-12 w-full gap-4 items-center pb-4 tab:pb-5">
              <div className="col-span-12 md:col-span-3 sm:col-span-6">
                {catelist != "" && (
                  <DropdownList
                    data={catelist}
                    name="All Categories"
                    width={"100%"}
                    type=""
                    setData={setCategory}
                  />
                )}
              </div>
              <div className="col-span-12 md:col-span-3 sm:col-span-6 tab:pt-0">
                {venList != "" && (
                  <DropdownList
                    data={venList}
                    name="All Vendors"
                    width={"100%"}
                    type=""
                    setData={setVendor}
                  />
                )}
              </div>
              <div className="col-span-12 md:col-span-3 sm:col-span-6 tab:pt-0">
                <DropdownList
                  data={AmountDropDown}
                  width={"100%"}
                  name="Amount Requested"
                  setData={setAmountRequested}
                />
              </div>
              <div className="col-span-12 md:col-span-3 sm:col-span-6 relative tab:pt-0 ">
                <div className="relative">
                  <input
                    className=" border border-primary/15 rounded-lg py-3 ps-3 pe-8 w-full text-sm focus:outline-none focus:ring-[0.4px] focus:ring-primary h-10 overflow-hidden placeholder:text-primary/70"
                    placeholder="Search Help Requests"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <div className="absolute top-1/2 -translate-y-1/2 right-3">
                    <CgSearch className="h-4 w-4 text-primary/50" />
                  </div>
                </div>
              </div>
            </div>
            {showToggleField && (
              <div className="grid grid-cols-12 w-full gap-4 items-center">
                <div className="col-span-12 md:col-span-3 sm:col-span-6 relative">
                  <div className="p-0 border border-primary/15 rounded-lg px-3 relative flex items-center h-10 w-full bg-white">
                    <DatePicker
                      placeholderText="Help Requested Date"
                      value={startDate}
                      onChange={handleSelectDate}
                      className="border-0 focus:outline-none text-primary/70 text-sm bg-transparent w-full"
                    />
                    <FaCalendar className="h-4 w-4 absolute right-5 text-primary/50" />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-9 sm:col-span-6 relative md:pt-0">
                  <div className="input flex items-center w-full bg-white h-10">
                    <input
                      className="border-0 focus:outline-none text-sm text-primary py-[1px] bg-transparent w-full placeholder:text-primary/70"
                      placeholder="Location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    <RiMapPinFill className="h-4 w-4 text-primary/50" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={`mt-5 border-t border-primary/20 ${
              showToggleField ? "mt-5" : "mt-0"
            }`}
          >
            <div className="flex justify-end items-center mt-4 font-bold gap-4">
              <h6 className="text-[14px]">Advanced Search</h6>
              <label class="relative inline-flex cursor-pointer items-center m-0">
                <input
                  id="switch"
                  type="checkbox"
                  class="peer sr-only"
                  onChange={handleToggleChange}
                />
                <label for="switch" class="hidden"></label>
                <div class="peer h-5 w-10 rounded-full border bg-primary/30 after:absolute after:top-[3px] after:left-[3px] after:h-[14px] after:w-[14px] after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-success peer-checked:after:translate-x-[19px]"></div>
              </label>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center mt-3">
            <img src={Loader} alt="Loader" className="w-14 h-14" />
          </div>
        ) : (
          <>
            {helpData.length > 0 ? (
              <div className="py-26 tab:py-30">
                <UserCard data={helpData} />
              </div>
            ) : (
              <div className="flex justify-center mt-3">
                <p className="mt-2 text-lg tab:text-xl">Data Not Found</p>
              </div>
            )}
            {totalHelpCount > helpData.length && (
              <div className="flex justify-center items-center pb-26">
                {isLoadMoreLoading ? (
                  <img src={Loader} alt="Loader" className="w-12 h-12" />
                ) : (
                  <button
                    className={`btn-big btn-outline-primary`}
                    type="button"
                    onClick={() => {
                      setHelpPage(helpPage + 1);
                      setLoadMoreClick(true);
                    }}
                  >
                    Load More
                  </button>
                )}
              </div>
            )}

            {/* Filter Part */}
            <h5 className="text-sm tab:text-lg">Helped by Me</h5>

            <div className="dashboard-card px-4 py-5 mt-4">
              <div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center pb-5 md:pb-0">
                  <div className="order-2 md:order-1">
                    {catelist != "" && (
                      <DropdownList
                        data={catelist}
                        name="All Categories"
                        width={"100%"}
                        type=""
                        setData={setHelpCategory}
                      />
                    )}
                  </div>
                  <div className="order-3 md:order-2">
                    {venList != "" && (
                      <DropdownList
                        data={venList}
                        name="All Vendors"
                        width={"100%"}
                        type=""
                        setData={setHelpVendor}
                      />
                    )}
                  </div>
                  <div className="order-4 md:order-3">
                    <DropdownList
                      data={AmountDropDown}
                      width={"100%"}
                      name="Amount Requested"
                      setData={setHelpDonatedAmount}
                    />
                  </div>
                  <div className="relative order-1 md:order-4">
                    <div className="relative">
                      <input
                        className=" border border-primary/15 rounded-lg py-3 ps-3 pe-8 w-full text-sm focus:outline-none focus:ring-[0.4px] focus:ring-primary h-10 overflow-hidden placeholder:text-primary/70"
                        placeholder="Search Help Requests"
                        value={helpSearch}
                        onChange={(e) => setHelpSearch(e.target.value)}
                      />
                      <div className="absolute top-1/2 -translate-y-1/2 right-3">
                        <CgSearch className="h-4 w-4 text-primary/50" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isHelpByMeLoading ? (
              <div className="flex justify-center mt-3">
                <img src={Loader} alt="Loader" className="w-14 h-14" />
              </div>
            ) : (
              <>
                {helpedByMeData.length > 0 ? (
                  <>
                    {/* Help Request Table */}
                    <div className="md:inline-block hidden w-full">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <strong>Date and Time</strong>
                            </th>
                            <th>
                              <strong>Invoice ID #</strong>
                            </th>
                            <th>
                              <strong>Bill Category</strong>
                            </th>
                            <th>
                              <strong>Person</strong>
                            </th>
                            <th>
                              <strong>Vendor</strong>
                            </th>
                            <th>
                              <strong>Amount</strong>
                            </th>
                            <th>
                              <strong>Action</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {helpedByMeData.map((item, index) => (
                            <tr key={index} className="bg-white shadow-card2">
                              <td>{`${moment(item.createdAt).format(
                                "DD MMM, YYYY"
                              )} at ${moment(item.createdAt).format(
                                "hh:mm A"
                              )}`}</td>
                              <td>{item.invoiceId}</td>
                              <td>
                                <span class="py-1 px-2 bg-blue/10 rounded">
                                  <strong class="text-blue">
                                    {item.category?.name}
                                  </strong>
                                </span>
                              </td>
                              <td>{item.user?.name}</td>
                              <td>{item.vendor?.name}</td>
                              <td>${item.amount}</td>
                              <td>
                                <div className="flex items-center">
                                  {rowLoading === index ? (
                                    <img
                                      src={Loader}
                                      alt="Loader"
                                      className="w-5 h-5"
                                    />
                                  ) : (
                                    <AiFillFolderOpen
                                      className="text-primary text-base cursor-pointer"
                                      onClick={() =>
                                        handleHelpByMeOpen(item, index)
                                      }
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="p-4 dashboard-card">
                        <CustomPagination
                          total={totalCount}
                          page={page}
                          handleChange={handleChange}
                          dataPerPage={pageSize}
                          handleDataPerPage={handleDataPerPage}
                          PAGE_SIZES={PAGE_SIZES}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="md:flex hidden justify-center mt-3">
                    <p className="mt-2 text-sm tab:text-xl">Data Not Found</p>
                  </div>
                )}
                {/* Mobile */}
                {helpedByMeData.length > 0 ? (
                  <div className="inline-block md:hidden w-full mt-4">
                    <div className="grid grid-cols-[repeat(auto-fill,minmax(330px,1fr))] gap-4">
                      {helpedByMeData.map((item, index) => (
                        <div className="dashboard-card p-4" key={index}>
                          <div className="b-b pb-3 flex justify-between items-center">
                            <div class="flex items-center gap-2.5">
                              <span class="py-1 px-2 bg-blue/10 rounded">
                                <strong class="text-blue">
                                  {item?.category?.name}
                                </strong>
                              </span>
                              <h6 class="text-sm">{item.vendor?.company}</h6>
                            </div>
                            {rowLoading === index ? (
                              <img
                                src={Loader}
                                alt="Loader"
                                className="w-5 h-5"
                              />
                            ) : (
                              <AiFillFolderOpen
                                className="text-primary text-base cursor-pointer"
                                onClick={() => handleHelpByMeOpen(item, index)}
                              />
                            )}
                          </div>
                          <div className="pt-3 flex flex-col gap-3">
                            <div className="flex items-center justify-between">
                              <p>
                                <strong className="text-primary/60">
                                  {`${moment(item.createdAt).format(
                                    "DD MMM, YYYY"
                                  )} at ${moment(item.createdAt).format(
                                    "hh:mm A"
                                  )}`}
                                </strong>
                              </p>
                              <p>
                                <strong className="text-primary/60">
                                  {item.invoiceId}
                                </strong>
                              </p>
                            </div>
                            <div className="flex items-center justify-between gap-2">
                              <div className="flex items-center gap-2.5">
                                <div className="h-[30px] w-[30px] rounded-lg overflow-hidden">
                                  {item.latestProfileImage ? (
                                    <img
                                      src={User}
                                      alt="User"
                                      className="w-full h-full bg-center bg-cover object-cover"
                                    />
                                  ) : (
                                    <AvatarIcon
                                      size={30}
                                      name={item.user?.name}
                                      className="rounded-lg overflow-hidden"
                                    />
                                  )}
                                </div>
                                <p className="text-base">{item.user?.name}</p>
                              </div>
                              <div className="flex flex-col gap-1.5 text-right">
                                <h5>${item.amount}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="md:hidden flex justify-center mt-3">
                    <p className="mt-2 text-lg tab:text-xl">Data Not Found</p>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {open && (
          <HelpByMeCard
            open={open}
            handleClickClose={handleCloseHelp}
            helpSingleData={helpSingleData}
            helpRef={helpRef}
          />
        )}
      </div>
    </div>
  );
};

export default UserGiveHelp;
