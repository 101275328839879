import React from "react";
import Avatar from "react-avatar";
import { useMediaQuery } from "react-responsive";

const AvatarIcon = ({ name, tabSize, size, className }) => {
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  let splitedName;

  if (name?.split(" ")[1] !== undefined) {
    splitedName = name?.split(" ")[0] + " " + name?.split(" ")[1];
  } else {
    splitedName = name?.split(" ")[0];
  }

  return (
    <Avatar
      name={splitedName}
      size={tabSize ? (isTablet ? tabSize : size) : size}
      className={className}
    />
  );
};

export default AvatarIcon;
